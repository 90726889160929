// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../styling/Ui__Styling.bs.js";
import * as ReactNative from "react-native";
import * as Ui__WebViewPager from "../viewPager/components/web/Ui__WebViewPager.bs.js";
import * as Bool$DecidrIoUtils from "@decidr-io/utils/src/bool/Bool.bs.js";
import * as Ui__DiscoverHeader from "../discoverHeader/components/Ui__DiscoverHeader.bs.js";
import * as FeatureFlags__LocalFeatureFlags from "../../../feature-flags/src/local/FeatureFlags__LocalFeatureFlags.bs.js";

var match = Ui__Styling.describe({
      activeIndicatorStyle: {
        TAG: /* Dual */2,
        _0: {
          height: 6,
          width: 6
        },
        _1: (function (theme) {
            return {
                    backgroundColor: theme.colors.primaryShade1
                  };
          })
      },
      inactiveIndicatorStyle: {
        TAG: /* Dual */2,
        _0: {
          height: 6,
          width: 6
        },
        _1: (function (theme) {
            return {
                    backgroundColor: theme.colors.primaryShade3
                  };
          })
      }
    });

var stylesheet = match[1];

var styles = match[0];

function Ui__DiscoverHero(Props) {
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet, theme);
  var match$1 = ReactNative.useWindowDimensions();
  var screenWidth = match$1.width;
  var screenSize = Ui__DiscoverHeader.useScreenSize(undefined);
  FeatureFlags__LocalFeatureFlags.use("implementOverstockedProducts");
  var style = screenSize === "small" ? (function (activeIndex) {
        var tmp = {};
        var tmp$1 = activeIndex >= 3 ? (
            activeIndex >= 4 ? undefined : theme.colors.secondary
          ) : (
            activeIndex >= 0 ? theme.colors.primary : undefined
          );
        if (tmp$1 !== undefined) {
          tmp.backgroundColor = Caml_option.valFromOption(tmp$1);
        }
        return tmp;
      }) : undefined;
  var indicatorsContainerStyle = screenSize === "small" ? ({
        bottom: theme.spacing._6,
        position: "absolute",
        width: screenWidth,
        zIndex: 30
      }) : undefined;
  var tmp = {
    children: null,
    showIndicators: true,
    indicatorStyle: (function (_ai, isActive) {
        if (isActive) {
          return Curry._1(styled, styles.activeIndicatorStyle);
        } else {
          return Curry._1(styled, styles.inactiveIndicatorStyle);
        }
      }),
    withAutoplay: true,
    scrollable: screenSize === "small",
    testID: "discover-hero"
  };
  if (style !== undefined) {
    tmp.style = Caml_option.valFromOption(style);
  }
  if (indicatorsContainerStyle !== undefined) {
    tmp.indicatorsContainerStyle = Caml_option.valFromOption(indicatorsContainerStyle);
  }
  var tmp$1 = Bool$DecidrIoUtils.thenSome(screenSize === "small", screenWidth);
  if (tmp$1 !== undefined) {
    tmp.scrollableWidth = tmp$1;
  }
  return React.createElement(Ui__WebViewPager.make, tmp, React.createElement(Ui__DiscoverHeader.Overstocked.make, {}), React.createElement(Ui__DiscoverHeader.Refurbished.make, {}));
}

var make = Ui__DiscoverHero;

export {
  make ,
}
/* match Not a pure module */
