// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Card from "../../../../../ui/src/card/Ui__Card.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__AppLink from "../../../../../ui/src/routing/Ui__AppLink.bs.js";
import * as Ui__Styling from "../../../../../ui/src/styling/Ui__Styling.bs.js";
import * as ReactRelay from "react-relay";
import * as ReactNative from "react-native";
import * as Ui__Responsive from "../../../../../ui/src/responsive/Ui__Responsive.bs.js";
import * as Ui__MarkotImage from "../../../../../ui/src/image/components/Ui__MarkotImage.bs.js";
import * as RescriptRelay_Internal from "rescript-relay/src/RescriptRelay_Internal.bs.js";
import * as AppCategoryCardWithCoverImage_category_graphql from "../../../../../relay-codegen/generated/AppCategoryCardWithCoverImage_category_graphql.bs.js";

function useCardSize(param) {
  return Curry.app(Ui__Responsive.useMedia(undefined)(undefined).mediaSelect, [
              undefined,
              {
                width: 253,
                height: 189
              },
              undefined,
              {
                width: 302,
                height: 206
              },
              {
                width: 348,
                height: 238
              },
              {
                width: 393,
                height: 269
              },
              {
                width: 463,
                height: 316
              },
              {
                width: 204,
                height: 139
              },
              undefined
            ]);
}

function use(fRef) {
  var data = ReactRelay.useFragment(AppCategoryCardWithCoverImage_category_graphql.node, fRef);
  return RescriptRelay_Internal.internal_useConvertedValue(AppCategoryCardWithCoverImage_category_graphql.Internal.convertFragment, data);
}

var match = Ui__Styling.describe({
      container: {
        TAG: /* Dual */2,
        _0: {
          alignItems: "center",
          justifyContent: "center"
        },
        _1: (function (props) {
            return {
                    backgroundColor: props.theme.colors.primary,
                    height: props.cardHeight,
                    width: props.cardWidth
                  };
          })
      },
      title: {
        TAG: /* Static */0,
        _0: {
          fontWeight: "500",
          textAlign: "center"
        }
      },
      imageBackground: {
        TAG: /* Static */0,
        _0: {
          opacity: 0.7
        }
      }
    });

var stylesheet = match[1];

var styles = match[0];

function App__Category__CardWithCoverImage(Props) {
  var category = Props.category;
  var style = Props.style;
  var testIDOpt = Props.testID;
  var testID = testIDOpt !== undefined ? testIDOpt : "category-card-with-cover-image";
  var category$1 = use(category);
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var media = Ui__Responsive.useMedia(undefined)(undefined);
  var match$1 = useCardSize(undefined);
  var cardWidth = match$1.width;
  var styled = Curry._1(stylesheet, {
        theme: theme,
        cardWidth: cardWidth,
        cardHeight: match$1.height
      });
  var iconSize = Curry.app(media.mediaSelect, [
        undefined,
        131,
        110,
        131,
        undefined,
        undefined,
        undefined,
        80,
        undefined
      ]);
  var match$2 = Curry._1(Ui__AppLink.useRouting, undefined);
  var routeConfiguration = Curry._3(Ui__AppLink.makeConfiguration, {
        TAG: /* Category */1,
        id: category$1.id,
        slug: category$1.slug
      }, undefined, undefined);
  var tmp = {
    children: null,
    kind: "sharp",
    spacing: theme.spacing._8,
    backgroundColor: Belt_Option.isSome(category$1.coverImage) ? "transparent" : theme.colors.primaryShade1,
    imageBackgroundStyle: Curry._1(styled, styles.imageBackground),
    accessibilityLabel: "" + category$1.name + " category",
    accessibilityRole: "article",
    sizes: Ui__MarkotImage.dpToSizes(cardWidth),
    style: [
      Caml_option.some(Curry._1(styled, styles.container)),
      style
    ],
    testID: testID,
    priority: "normal"
  };
  var tmp$1 = Belt_Option.map(match$2.nativeNavigationHandler, (function (navHandler, param) {
          Curry._1(navHandler, routeConfiguration);
        }));
  if (tmp$1 !== undefined) {
    tmp.onPress = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = Belt_Option.map(category$1.coverImage, (function (param) {
          var match = param.size;
          return {
                  image: {
                    src: param.url,
                    width: match.width,
                    height: match.height
                  },
                  resizeMode: "cover"
                };
        }));
  if (tmp$2 !== undefined) {
    tmp.imageBackground = Caml_option.valFromOption(tmp$2);
  }
  var icon = category$1.icon;
  var tmp$3;
  if (icon !== undefined) {
    var match$3 = icon.size;
    tmp$3 = React.createElement(Ui__Card.$$Image.make, {
          image: {
            src: icon.url,
            width: match$3.width,
            height: match$3.height
          },
          size: {
            NAME: "fixedWidth",
            VAL: {
              width: iconSize,
              aspectRatio: 1
            }
          },
          resizeMode: "contain",
          alt: "" + category$1.name + " icon",
          priority: "normal",
          testID: "category-card-icon",
          tintColor: theme.colors.secondary
        });
  } else {
    tmp$3 = React.createElement(ReactNative.View, {
          style: {
            height: iconSize,
            width: iconSize
          }
        });
  }
  return React.createElement(Ui__AppLink.make, {
              configuration: routeConfiguration,
              children: React.createElement(Ui__Card.make, tmp, tmp$3, React.createElement(Ui__Card.Description.make, {
                        text: category$1.name,
                        size: "b1",
                        fontFamily: theme.fontFamily.dm,
                        numberOfLines: 2,
                        style: Curry._1(styled, styles.title),
                        color: theme.colors.surface,
                        accessibilityRole: "header",
                        accessibilityLevel: 3
                      })),
              a11yLabel: "Go browsing products by " + category$1.name + " category"
            });
}

var make = App__Category__CardWithCoverImage;

export {
  useCardSize ,
  make ,
}
/* match Not a pure module */
