// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Group from "../../group/components/Ui__Group.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../../styling/Ui__Styling.bs.js";
import * as Utils__A11y from "../../../../utils/src/Utils__A11y.bs.js";
import * as ReactNative from "react-native";
import * as Ui__MarkotIcon from "../../icons/components/Ui__MarkotIcon.bs.js";
import * as Ui__MarkotText from "../../text/components/Ui__MarkotText.bs.js";
import * as Ui__Responsive from "../../responsive/Ui__Responsive.bs.js";
import * as Tiles$DecidrIoUniversalComponents from "@decidr-io/universal-components/src/layout/tiles/components/Tiles.bs.js";

var widthLimiterStyle = Ui__Responsive.widthLimiterStyle(undefined, 1536, undefined);

var match = Ui__Styling.describe({
      container: {
        TAG: /* Dual */2,
        _0: {
          alignItems: "center",
          height: 180,
          justifyContent: "space-between"
        },
        _1: (function (theme) {
            return {
                    backgroundColor: theme.colors.surface,
                    paddingHorizontal: theme.spacing._5,
                    paddingVertical: theme.spacing._18
                  };
          })
      },
      title: {
        TAG: /* Static */0,
        _0: {
          textAlign: "center"
        }
      }
    });

var stylesheet = match[1];

var styles = match[0];

function Ui__MarkotFeatures$Card(Props) {
  var iconName = Props.iconName;
  var iconSize = Props.iconSize;
  var label = Props.label;
  var containerStyle = Props.containerStyle;
  var testIDOpt = Props.testID;
  var testID = testIDOpt !== undefined ? testIDOpt : "card";
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet, theme);
  var tmp = {
    name: iconName,
    testID: "icon"
  };
  if (iconSize !== undefined) {
    tmp.size = iconSize;
  }
  return React.createElement(Ui__Group.make, {
              spacing: theme.spacing._10,
              children: null,
              containerStyle: [
                Caml_option.some(Curry._1(styled, styles.container)),
                containerStyle
              ],
              testID: testID,
              accessibilityRole: "article"
            }, React.createElement(Ui__MarkotIcon.make, tmp), React.createElement(Ui__MarkotText.make, {
                  children: label,
                  style: Curry._1(styled, styles.title),
                  accessibilityRole: "header",
                  accessibilityLevel: 3
                }));
}

var match$1 = Ui__Styling.describe({
      container: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    backgroundColor: theme.colors.secondaryVariant2
                  };
          })
      },
      content: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    paddingHorizontal: theme.spacing._10,
                    paddingVertical: theme.sectionSpacing._2
                  };
          })
      },
      title: {
        TAG: /* Static */0,
        _0: {
          textAlign: "center"
        }
      },
      thirdCard: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    paddingHorizontal: theme.spacing._8
                  };
          })
      }
    });

var stylesheet$1 = match$1[1];

var styles$1 = match$1[0];

function Ui__MarkotFeatures(Props) {
  var testIDOpt = Props.testID;
  var testID = testIDOpt !== undefined ? testIDOpt : "more-reasons-to-love-markot";
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet$1, theme);
  var columns = Curry.app(Ui__Responsive.useMedia(undefined)(undefined).mediaSelect, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        3,
        undefined,
        2,
        undefined
      ]);
  var tmp = {
    spacing: theme.sectionSpacing._2,
    children: null,
    containerStyle: [
      Curry._1(styled, styles$1.content),
      widthLimiterStyle
    ],
    testID: testID
  };
  var tmp$1 = Utils__A11y.toSafeA11yRole("complementary");
  if (tmp$1 !== undefined) {
    tmp.accessibilityRole = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(ReactNative.View, {
              style: Curry._1(styled, styles$1.container),
              children: React.createElement(Ui__Group.make, tmp, React.createElement(Ui__MarkotText.make, {
                        fontFamily: "libre",
                        size: "title1",
                        children: "More reasons to love Markot",
                        style: Curry._1(styled, styles$1.title),
                        accessibilityRole: "header",
                        accessibilityLevel: 2
                      }), React.createElement(Tiles$DecidrIoUniversalComponents.make, {
                        children: null,
                        columns: columns,
                        spacing: 20
                      }, React.createElement(Ui__MarkotFeatures$Card, {
                            iconName: "check-mark-in-circle",
                            iconSize: 40,
                            label: "Everything is checked & cleaned."
                          }), React.createElement(Ui__MarkotFeatures$Card, {
                            iconName: "tag-tilted",
                            iconSize: 50,
                            label: "Shop the best brands new."
                          }), React.createElement(Ui__MarkotFeatures$Card, {
                            iconName: "triangle-in-pill",
                            iconSize: 60,
                            label: "Sell in just a few clicks",
                            containerStyle: Curry._1(styled, styles$1.thirdCard)
                          }), React.createElement(Ui__MarkotFeatures$Card, {
                            iconName: "truck",
                            iconSize: 67,
                            label: "Pickup & delivery available"
                          }), React.createElement(Ui__MarkotFeatures$Card, {
                            iconName: "conversation-cloud",
                            iconSize: 45,
                            label: "No more painful messages."
                          }), React.createElement(Ui__MarkotFeatures$Card, {
                            iconName: "key-outlined",
                            iconSize: 45,
                            label: "Don't give your address to strangers"
                          })))
            });
}

var reasonCardHeight = 180;

var Card = {
  make: Ui__MarkotFeatures$Card
};

var make = Ui__MarkotFeatures;

export {
  reasonCardHeight ,
  Card ,
  make ,
}
/* widthLimiterStyle Not a pure module */
