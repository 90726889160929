// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Group from "../../group/components/Ui__Group.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Heading from "../../text/components/Ui__Heading.bs.js";
import * as Ui__Styling from "../../styling/Ui__Styling.bs.js";
import * as Utils__A11y from "../../../../utils/src/Utils__A11y.bs.js";
import * as ReactNative from "react-native";
import * as Ui__HeroHeart from "../../svg/heart/Ui__HeroHeart.bs.js";
import * as Ui__LinkButton from "../../linkButton/components/Ui__LinkButton.bs.js";
import * as Ui__MarkotIcon from "../../icons/components/Ui__MarkotIcon.bs.js";
import * as Ui__MarkotText from "../../text/components/Ui__MarkotText.bs.js";
import * as Ui__Responsive from "../../responsive/Ui__Responsive.bs.js";
import * as Ui__MarkotImage from "../../image/components/Ui__MarkotImage.bs.js";
import * as Ui__PlatformLink from "../../routing/Ui__PlatformLink.bs.js";
import * as Ui__MultilineText from "../../multipleLines/components/Ui__MultilineText.bs.js";
import * as Bool$DecidrIoUtils from "@decidr-io/utils/src/bool/Bool.bs.js";
import * as Ui__SquigglyCircle from "../../svg/squigglyCircle/Ui__SquigglyCircle.bs.js";
import * as Ui__BigRightArrowSvg from "../../svg/bigRightArrowSvg/Ui__BigRightArrowSvg.bs.js";
import * as Ui__OverstockedBadge from "../../overstockedBadge/Ui__OverstockedBadge.bs.js";
import * as Ui__VerificationMark from "../../verificationMark/Ui__VerificationMark.bs.js";
import * as FeatureFlags__LocalFeatureFlags from "../../../../feature-flags/src/local/FeatureFlags__LocalFeatureFlags.bs.js";

var imageSrc = "https://cdn.filestackcontent.com/kH4niX0Rbuf0PZUir2GA";

var imageAlt = "Lady putting a bassinet inside Markot box";

var match = Ui__Styling.describe({
      largeContainer: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    paddingHorizontal: theme.sectionSpacing._2,
                    paddingTop: theme.sectionSpacing._1
                  };
          })
      }
    });

var stylesheet = match[1];

var styles = match[0];

function Ui__DiscoverHeader$LargeScreenContainer(Props) {
  var children = Props.children;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet, theme);
  var widthLimiterStyle = Ui__Responsive.widthLimiterStyle(undefined, 1536 + 2 * theme.sectionSpacing._2, undefined);
  var redesignDiscoverHeroBanner = FeatureFlags__LocalFeatureFlags.use("redesignDiscoverHeroBanner");
  var newStyle = redesignDiscoverHeroBanner === "on" ? ({
        paddingTop: theme.spacing._10
      }) : ({});
  return React.createElement(ReactNative.View, {
              style: [
                Curry._1(styled, styles.largeContainer),
                newStyle,
                widthLimiterStyle
              ],
              children: children
            });
}

var match$1 = Ui__Styling.describe({
      imageContainer: {
        TAG: /* Dual */2,
        _0: {
          alignItems: "flex-start",
          flexDirection: "row",
          height: 617
        },
        _1: (function (theme) {
            return {
                    padding: theme.spacing._14
                  };
          })
      },
      circle: {
        TAG: /* Dual */2,
        _0: {
          alignItems: "center",
          justifyContent: "center"
        },
        _1: (function (theme) {
            return {
                    backgroundColor: theme.colors.secondary,
                    borderRadius: theme.roundness.circle,
                    padding: theme.sectionSpacing._1
                  };
          })
      },
      heading: {
        TAG: /* Static */0,
        _0: {
          textAlign: "center"
        }
      },
      headingContainer: {
        TAG: /* Dual */2,
        _0: {
          alignItems: "center",
          justifyContent: "center"
        },
        _1: (function (theme) {
            return {
                    backgroundColor: theme.colors.secondary,
                    padding: theme.spacing._8,
                    paddingBottom: 30
                  };
          })
      },
      smallScreenHeading: {
        TAG: /* Static */0,
        _0: {
          textAlign: "center",
          maxWidth: 260
        }
      }
    });

var stylesheet$1 = match$1[1];

var styles$1 = match$1[0];

function useSmallScreenHeight(param) {
  return Curry.app(Ui__Responsive.useMedia(undefined)(undefined).mediaSelect, [
              undefined,
              undefined,
              270,
              360,
              340,
              340,
              undefined,
              200,
              undefined
            ]);
}

function useScreenSize(param) {
  return Curry.app(Ui__Responsive.useMedia(undefined)(undefined).mediaSelect, [
              undefined,
              undefined,
              "small",
              undefined,
              "large",
              undefined,
              undefined,
              "small",
              undefined
            ]);
}

function useScreenSizeOnlyImage(param) {
  return Curry.app(Ui__Responsive.useMedia(undefined)(undefined).mediaSelect, [
              "xxs",
              "xs",
              "s",
              "m",
              "l",
              "xl",
              "xxl",
              "s",
              undefined
            ]);
}

function useOnImageElementSize(param) {
  return Curry.app(Ui__Responsive.useMedia(undefined)(undefined).mediaSelect, [
              undefined,
              undefined,
              240,
              340,
              480,
              561,
              undefined,
              340,
              undefined
            ]);
}

var textLines = [
  "Markot is a curated marketplace",
  "for the most sought-after baby",
  "brands around the world.",
  "Buy and sell seamlessly, safely",
  "and sustainably."
];

function Ui__DiscoverHeader(Props) {
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet$1, theme);
  var smallScreenImageHeight = useSmallScreenHeight(undefined);
  var screenSize = useScreenSize(undefined);
  var circleSize = useOnImageElementSize(undefined);
  var redesignDiscoverHeroBanner = FeatureFlags__LocalFeatureFlags.use("redesignDiscoverHeroBanner");
  if (screenSize === "small") {
    var tmp = {
      children: null
    };
    var tmp$1 = Utils__A11y.toSafeA11yRole("complementary");
    if (tmp$1 !== undefined) {
      tmp.accessibilityRole = Caml_option.valFromOption(tmp$1);
    }
    return React.createElement(ReactNative.View, tmp, React.createElement(ReactNative.View, {
                    style: {
                      height: smallScreenImageHeight - 2
                    },
                    children: React.createElement(Ui__MarkotImage.make, {
                          image: {
                            src: imageSrc,
                            width: 1348,
                            height: 617
                          },
                          alt: imageAlt,
                          size: {
                            NAME: "fixedHeightAutoWidth",
                            VAL: {
                              height: smallScreenImageHeight,
                              sizes: "100vw"
                            }
                          },
                          resizeMode: "cover"
                        })
                  }), redesignDiscoverHeroBanner === "off" ? React.createElement(ReactNative.View, {
                      style: [
                        Curry._1(styled, styles$1.headingContainer),
                        {
                          paddingBottom: theme.spacing._8
                        }
                      ],
                      children: React.createElement(Ui__Heading.make, {
                            fontFamily: theme.fontFamily.libre,
                            size: "title2",
                            level: 2,
                            children: "A better way to buy & sell baby items.",
                            style: Curry._1(styled, styles$1.smallScreenHeading)
                          })
                    }) : React.createElement(Ui__Group.make, {
                      spacing: theme.spacing._10,
                      children: null,
                      containerStyle: Curry._1(styled, styles$1.headingContainer)
                    }, React.createElement(Ui__Heading.make, {
                          fontFamily: theme.fontFamily.libre,
                          size: "title1",
                          level: 2,
                          children: "A better marketplace",
                          style: Curry._1(styled, styles$1.smallScreenHeading)
                        }), React.createElement(Ui__MultilineText.make, {
                          textLines: textLines,
                          color: theme.colors.primary,
                          spacing: theme.spacing._2,
                          size: "b1"
                        })));
  }
  var tmp$2 = {
    children: React.createElement(Ui__Group.make, {
          spacing: theme.spacing._14,
          children: null,
          containerStyle: [
            Curry._1(styled, styles$1.circle),
            {
              height: circleSize,
              width: circleSize
            }
          ]
        }, React.createElement(Ui__Heading.make, {
              fontFamily: theme.fontFamily.libre,
              size: {
                NAME: "unsafe",
                VAL: {
                  size: 48,
                  lineHeight: 56,
                  letterSpacingPct: 0
                }
              },
              level: 2,
              children: "A better marketplace",
              style: Curry._1(styled, styles$1.heading)
            }), React.createElement(Ui__MultilineText.make, {
              textLines: textLines,
              color: theme.colors.primary,
              spacing: theme.spacing._2,
              size: "title3"
            })),
    containerStyle: Curry._1(styled, styles$1.imageContainer),
    accessibilityLabel: imageAlt,
    imageBackground: {
      image: {
        src: imageSrc,
        width: 1348,
        height: 617
      },
      resizeMode: "cover"
    },
    resizeMode: "cover",
    sizes: "90vw",
    priority: "high"
  };
  var tmp$3 = Utils__A11y.toSafeA11yRole("complementary");
  if (tmp$3 !== undefined) {
    tmp$2.accessibilityRole = Caml_option.valFromOption(tmp$3);
  }
  return React.createElement(Ui__DiscoverHeader$LargeScreenContainer, {
              children: React.createElement(Ui__Group.make, tmp$2)
            });
}

function Ui__DiscoverHeader$DiscountCodePromo$DiscountNotice(Props) {
  var positionOpt = Props.position;
  var textSizeOpt = Props.textSize;
  var withBackgroundOpt = Props.withBackground;
  var position = positionOpt !== undefined ? positionOpt : "bottomRight";
  var textSize = textSizeOpt !== undefined ? textSizeOpt : "b1";
  var withBackground = withBackgroundOpt !== undefined ? withBackgroundOpt : false;
  var positionStyle = position === "bottomLeft" ? ({
        bottom: 0,
        left: 0
      }) : ({
        bottom: 0,
        right: 0
      });
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var tmp = {
    padding: theme.spacing._2,
    position: "absolute"
  };
  var tmp$1 = Bool$DecidrIoUtils.thenSome(withBackground, "rgba(255,255,255,0.5)");
  if (tmp$1 !== undefined) {
    tmp.backgroundColor = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = {
    size: textSize,
    children: "*for new items only"
  };
  var tmp$3 = Bool$DecidrIoUtils.thenSome(!withBackground, theme.colors.onPrimary);
  if (tmp$3 !== undefined) {
    tmp$2.color = Caml_option.valFromOption(tmp$3);
  }
  return React.createElement(ReactNative.View, {
              style: [
                tmp,
                positionStyle
              ],
              children: React.createElement(Ui__MarkotText.make, tmp$2)
            });
}

var DiscountNotice = {
  make: Ui__DiscoverHeader$DiscountCodePromo$DiscountNotice
};

function Ui__DiscoverHeader$DiscountCodePromo(Props) {
  var smallScreenImageHeight = Curry.app(Ui__Responsive.useMedia(undefined)(undefined).mediaSelect, [
        undefined,
        275,
        320,
        380,
        340,
        340,
        undefined,
        200,
        undefined
      ]);
  var promoImageV1 = "https://cdn.filestackcontent.com/Nyrzx2hQxu7FaF9FrUtA";
  var promoImageV2 = "https://cdn.filestackcontent.com/jZEbfFpSUKbI64jVkVox";
  var imageUrl = Curry.app(Ui__Responsive.useMedia(undefined)(undefined).mediaSelect, [
        undefined,
        promoImageV1,
        promoImageV2,
        promoImageV1,
        promoImageV2,
        promoImageV2,
        undefined,
        "https://cdn.filestackcontent.com/5Ay9SiavS2WZ0R4jVjZQ",
        undefined
      ]);
  var screenSize = useScreenSize(undefined);
  if (screenSize === "small") {
    return React.createElement(ReactNative.View, {
                children: React.createElement(Ui__MarkotImage.make, {
                      image: {
                        src: imageUrl,
                        width: 1348,
                        height: 617
                      },
                      alt: imageAlt,
                      size: {
                        NAME: "fixedHeightAutoWidth",
                        VAL: {
                          height: smallScreenImageHeight,
                          sizes: "100vw"
                        }
                      },
                      resizeMode: "cover",
                      priority: "high"
                    })
              });
  } else {
    return React.createElement(Ui__DiscoverHeader$LargeScreenContainer, {
                children: React.createElement(ReactNative.View, {
                      children: React.createElement(Ui__MarkotImage.make, {
                            image: {
                              src: "https://cdn.filestackcontent.com/jZEbfFpSUKbI64jVkVox",
                              width: 1348,
                              height: 617
                            },
                            alt: imageAlt,
                            size: {
                              NAME: "fixedHeightAutoWidth",
                              VAL: {
                                height: 617,
                                sizes: "100vw"
                              }
                            },
                            resizeMode: "cover",
                            priority: "high"
                          })
                    })
              });
  }
}

var match$2 = Ui__Styling.describe({
      bulletListContainer: {
        TAG: /* Dual */2,
        _0: {
          alignItems: "center"
        },
        _1: (function (theme) {
            return {
                    paddingVertical: theme.spacing._10
                  };
          })
      },
      bulletListItem: {
        TAG: /* Static */0,
        _0: {
          alignItems: "center"
        }
      }
    });

var stylesheet$2 = match$2[1];

var styles$2 = match$2[0];

function Ui__DiscoverHeader$BulletList(Props) {
  var icon = Props.icon;
  var bulletPoints = Props.bulletPoints;
  var spacing = Props.spacing;
  var titleStyle = Props.titleStyle;
  var containerStyle = Props.containerStyle;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet$2, theme);
  return React.createElement(Ui__Group.make, {
              spacing: spacing,
              children: Belt_Array.map(bulletPoints, (function (bulletPoint) {
                      var tmp = {
                        size: "b1",
                        children: bulletPoint.content
                      };
                      if (titleStyle !== undefined) {
                        tmp.style = Caml_option.valFromOption(titleStyle);
                      }
                      return React.createElement(Ui__Group.make, {
                                  spacing: theme.spacing._4,
                                  children: null,
                                  containerStyle: Curry._1(styled, styles$2.bulletListItem),
                                  orientation: "horizontal"
                                }, icon, React.createElement(Ui__MarkotText.make, tmp));
                    })),
              containerStyle: [
                Caml_option.some(Curry._1(styled, styles$2.bulletListContainer)),
                containerStyle
              ]
            });
}

var match$3 = Ui__Styling.describe({
      imageContainer: {
        TAG: /* Dual */2,
        _0: {
          alignItems: "flex-start",
          flexDirection: "row",
          height: 617
        },
        _1: (function (theme) {
            return {
                    padding: theme.spacing._14
                  };
          })
      },
      circle: {
        TAG: /* Dual */2,
        _0: {
          alignItems: "center",
          justifyContent: "center"
        },
        _1: (function (theme) {
            return {
                    borderRadius: theme.roundness.circle,
                    paddingHorizontal: theme.sectionSpacing._05,
                    paddingVertical: theme.sectionSpacing._05
                  };
          })
      },
      heading: {
        TAG: /* Dual */2,
        _0: {
          textAlign: "center",
          zIndex: 10
        },
        _1: (function (theme) {
            return {
                    paddingHorizontal: theme.spacing._10
                  };
          })
      },
      headingContainer: {
        TAG: /* Dual */2,
        _0: {
          alignItems: "center",
          justifyContent: "center"
        },
        _1: (function (theme) {
            return {
                    backgroundColor: theme.colors.secondary,
                    padding: theme.spacing._8,
                    paddingBottom: 30
                  };
          })
      },
      smallScreenHeading: {
        TAG: /* Static */0,
        _0: {
          textAlign: "center",
          maxWidth: 300
        }
      },
      linkStyle: {
        TAG: /* Static */0,
        _0: {
          flex: 1
        }
      }
    });

var stylesheet$3 = match$3[1];

var styles$3 = match$3[0];

function Ui__DiscoverHeader$HeaderWithListAndLink(Props) {
  var title = Props.title;
  var titleStyle = Props.titleStyle;
  var bulletPoints = Props.bulletPoints;
  var bulletPointStyle = Props.bulletPointStyle;
  var largeImageSrc = Props.largeImageSrc;
  var smallImageSrc = Props.smallImageSrc;
  var linkTitle = Props.linkTitle;
  var linkStyle = Props.linkStyle;
  var linkTextStyle = Props.linkTextStyle;
  var linkContainerStyle = Props.linkContainerStyle;
  var linkConfiguration = Props.linkConfiguration;
  var onImageElement = Props.onImageElement;
  var bulletIcon = Props.bulletIcon;
  var backgroundColor = Props.backgroundColor;
  var largeBulletContainerStyle = Props.largeBulletContainerStyle;
  var imageContainerStyle = Props.imageContainerStyle;
  var smallScreenHeadingStyle = Props.smallScreenHeadingStyle;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet$3, theme);
  var smallScreenImageHeight = useSmallScreenHeight(undefined);
  var screenSize = useScreenSize(undefined);
  var onImageElementSize = useOnImageElementSize(undefined);
  if (screenSize === "small") {
    var tmp = {
      icon: Curry._1(bulletIcon, 14),
      bulletPoints: Belt_Array.keep(bulletPoints, (function (param) {
              return param.showOnMobile;
            })),
      spacing: theme.spacing._8
    };
    if (bulletPointStyle !== undefined) {
      tmp.titleStyle = Caml_option.valFromOption(bulletPointStyle);
    }
    var tmp$1 = {
      kind: "primary",
      routeConfiguration: linkConfiguration,
      title: linkTitle,
      containerStyle: [
        Bool$DecidrIoUtils.thenSome(ReactNative.Platform.OS !== "web", Curry._1(styled, styles$3.linkStyle)),
        linkContainerStyle
      ]
    };
    if (linkTextStyle !== undefined) {
      tmp$1.textStyles = Caml_option.valFromOption(linkTextStyle);
    }
    var tmp$2 = {
      children: React.createElement(Ui__PlatformLink.make, {
            linkConfiguration: linkConfiguration,
            children: null
          }, React.createElement(ReactNative.View, {
                style: [
                  {
                    height: smallScreenImageHeight - 2
                  },
                  imageContainerStyle
                ],
                children: React.createElement(Ui__MarkotImage.make, {
                      image: {
                        src: smallImageSrc,
                        width: 1348,
                        height: 617
                      },
                      alt: imageAlt,
                      size: {
                        NAME: "fixedHeightAutoWidth",
                        VAL: {
                          height: smallScreenImageHeight,
                          sizes: "100vw"
                        }
                      },
                      resizeMode: "cover"
                    })
              }), React.createElement(ReactNative.View, {
                style: [
                  Curry._1(styled, styles$3.headingContainer),
                  {
                    backgroundColor: backgroundColor
                  }
                ],
                children: null
              }, React.createElement(Ui__Heading.make, {
                    fontFamily: theme.fontFamily.libre,
                    size: "title1",
                    level: 2,
                    children: title,
                    style: [
                      Caml_option.some(Curry._1(styled, styles$3.smallScreenHeading)),
                      smallScreenHeadingStyle
                    ]
                  }), React.createElement(Ui__DiscoverHeader$BulletList, tmp), React.createElement(Ui__LinkButton.make, tmp$1)))
    };
    var tmp$3 = Utils__A11y.toSafeA11yRole("complementary");
    if (tmp$3 !== undefined) {
      tmp$2.accessibilityRole = Caml_option.valFromOption(tmp$3);
    }
    return React.createElement(ReactNative.View, tmp$2);
  }
  var tmp$4 = {
    icon: Curry._1(bulletIcon, 24),
    bulletPoints: bulletPoints,
    spacing: theme.spacing._10
  };
  if (bulletPointStyle !== undefined) {
    tmp$4.titleStyle = Caml_option.valFromOption(bulletPointStyle);
  }
  if (largeBulletContainerStyle !== undefined) {
    tmp$4.containerStyle = Caml_option.valFromOption(largeBulletContainerStyle);
  }
  var tmp$5 = {
    kind: "primary",
    routeConfiguration: linkConfiguration,
    title: linkTitle
  };
  if (linkContainerStyle !== undefined) {
    tmp$5.containerStyle = Caml_option.valFromOption(linkContainerStyle);
  }
  if (linkTextStyle !== undefined) {
    tmp$5.textStyles = Caml_option.valFromOption(linkTextStyle);
  }
  if (linkStyle !== undefined) {
    tmp$5.linkStyle = Caml_option.valFromOption(linkStyle);
  }
  var tmp$6 = {
    children: React.createElement(Ui__Group.make, {
          spacing: theme.spacing._2,
          children: null,
          containerStyle: [
            Curry._1(styled, styles$3.circle),
            {
              height: onImageElementSize,
              width: onImageElementSize
            }
          ]
        }, React.createElement(ReactNative.View, {
              style: {
                position: "absolute"
              },
              children: Curry._1(onImageElement, onImageElementSize)
            }), React.createElement(Ui__Heading.make, {
              fontFamily: theme.fontFamily.libre,
              size: {
                NAME: "unsafe",
                VAL: {
                  size: 40,
                  lineHeight: 48,
                  letterSpacingPct: 0
                }
              },
              level: 2,
              children: title,
              style: [
                Caml_option.some(Curry._1(styled, styles$3.heading)),
                titleStyle
              ]
            }), React.createElement(Ui__DiscoverHeader$BulletList, tmp$4), React.createElement(Ui__LinkButton.make, tmp$5)),
    containerStyle: [
      Caml_option.some(Curry._1(styled, styles$3.imageContainer)),
      imageContainerStyle
    ],
    accessibilityLabel: imageAlt,
    imageBackground: {
      image: {
        src: largeImageSrc,
        width: 1348,
        height: 617
      },
      resizeMode: "cover"
    },
    resizeMode: "cover",
    sizes: "90vw",
    priority: "high"
  };
  var tmp$7 = Utils__A11y.toSafeA11yRole("complementary");
  if (tmp$7 !== undefined) {
    tmp$6.accessibilityRole = Caml_option.valFromOption(tmp$7);
  }
  return React.createElement(Ui__DiscoverHeader$LargeScreenContainer, {
              children: React.createElement(Ui__PlatformLink.make, {
                    linkConfiguration: linkConfiguration,
                    children: React.createElement(Ui__Group.make, tmp$6)
                  })
            });
}

var bulletPoints = [
  {
    content: "Premium brands, cleaned and sanitised",
    showOnMobile: true
  },
  {
    content: "Best brands at affordable prices",
    showOnMobile: true
  },
  {
    content: "Price is as marked",
    showOnMobile: false
  },
  {
    content: "Same day dispatch",
    showOnMobile: true
  }
];

function Ui__DiscoverHeader$Refurbished(Props) {
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  return React.createElement(Ui__DiscoverHeader$HeaderWithListAndLink, {
              title: "Introducing Refurbished",
              bulletPoints: bulletPoints,
              largeImageSrc: "https://cdn.filestackcontent.com/Uk5HF9H0QiJU7h1MbN3k",
              smallImageSrc: "https://cdn.filestackcontent.com/AiyDL9z0R2Kpyn8nIV6D",
              linkTitle: "Shop now",
              linkConfiguration: {
                route: {
                  TAG: /* Search */18,
                  searchTerm: "Refurbished",
                  loadDataInitially: true,
                  sellingNewItems: undefined,
                  brand: undefined,
                  category: undefined,
                  productType: "Refurbished"
                },
                a11yLabel: "Shop refurbished now"
              },
              onImageElement: (function (size) {
                  return React.createElement(Ui__SquigglyCircle.make, {
                              size: size
                            });
                }),
              bulletIcon: (function (size) {
                  return React.createElement(Ui__VerificationMark.make, {
                              size: size,
                              backgroundColor: theme.colors.secondaryVariant,
                              checkmarkColor: theme.colors.primary
                            });
                }),
              backgroundColor: theme.colors.secondary
            });
}

var bulletPoints$1 = [
  {
    content: "Item is unused",
    showOnMobile: true
  },
  {
    content: "With tags in sealed original packaging",
    showOnMobile: true
  },
  {
    content: "Mostly excess stock from vendor",
    showOnMobile: true
  }
];

var match$4 = Ui__Styling.describe({
      titleStyle: {
        TAG: /* Dual */2,
        _0: {
          textAlign: "left",
          alignSelf: "flex-start",
          maxWidth: 300
        },
        _1: (function (theme) {
            return {
                    color: theme.colors.backgroundVariant
                  };
          })
      },
      bulletPointStyle: {
        TAG: /* Dual */2,
        _0: {
          maxWidth: 350
        },
        _1: (function (theme) {
            return {
                    color: theme.colors.backgroundVariant
                  };
          })
      },
      linkStyle: {
        TAG: /* Dual */2,
        _0: {
          alignSelf: "flex-start"
        },
        _1: (function (theme) {
            return {
                    paddingLeft: theme.spacing._10
                  };
          })
      },
      linkTextStyle: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    color: theme.colors.onBackgroundVariant
                  };
          })
      },
      linkContainerStyle: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    backgroundColor: theme.colors.backgroundVariant
                  };
          })
      },
      largeBulletContainerStyle: {
        TAG: /* Dual */2,
        _0: {
          alignItems: "flex-start",
          alignSelf: "flex-start"
        },
        _1: (function (theme) {
            return {
                    paddingLeft: theme.spacing._10
                  };
          })
      },
      imageContainerStyle: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    backgroundColor: theme.colors.secondaryShade1
                  };
          })
      },
      smallScreenHeading: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    color: theme.colors.onPrimary
                  };
          })
      }
    });

var stylesheet$4 = match$4[1];

var styles$4 = match$4[0];

function Ui__DiscoverHeader$Overstocked(Props) {
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet$4, theme);
  return React.createElement(Ui__DiscoverHeader$HeaderWithListAndLink, {
              title: "What is overstocked?",
              titleStyle: Curry._1(styled, styles$4.titleStyle),
              bulletPoints: bulletPoints$1,
              bulletPointStyle: Curry._1(styled, styles$4.bulletPointStyle),
              largeImageSrc: "https://cdn.filestackcontent.com/Z6dRoW1SNm5BgXnvOzkQ",
              smallImageSrc: "https://cdn.filestackcontent.com/YHdWNgSHRDSV56txcxkg",
              linkTitle: "Shop now",
              linkStyle: Curry._1(styled, styles$4.linkStyle),
              linkTextStyle: Curry._1(styled, styles$4.linkTextStyle),
              linkContainerStyle: Curry._1(styled, styles$4.linkContainerStyle),
              linkConfiguration: {
                route: {
                  TAG: /* Search */18,
                  searchTerm: "Overstocked",
                  loadDataInitially: true,
                  sellingNewItems: undefined,
                  brand: undefined,
                  category: undefined,
                  productType: "Overstocked"
                },
                a11yLabel: "Shop overstocked now"
              },
              onImageElement: (function (size) {
                  return React.createElement(Ui__BigRightArrowSvg.make, {
                              size: size
                            });
                }),
              bulletIcon: (function (size) {
                  return React.createElement(Ui__OverstockedBadge.make, {
                              size: size,
                              circleColor: "#D2C7B3",
                              symbolColor: "#282828"
                            });
                }),
              backgroundColor: theme.colors.primary,
              largeBulletContainerStyle: Curry._1(styled, styles$4.largeBulletContainerStyle),
              imageContainerStyle: Curry._1(styled, styles$4.imageContainerStyle),
              smallScreenHeadingStyle: Curry._1(styled, styles$4.smallScreenHeading)
            });
}

var bulletPoints$2 = [
  {
    content: "Bid on premium brands for the best price",
    showOnMobile: true
  },
  {
    content: "Be part of the circular economy",
    showOnMobile: true
  },
  {
    content: "Safe, secure and anonymous",
    showOnMobile: true
  }
];

function Ui__DiscoverHeader$Preloved(Props) {
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  return React.createElement(Ui__DiscoverHeader$HeaderWithListAndLink, {
              title: "Why we love Preloved",
              bulletPoints: bulletPoints$2,
              largeImageSrc: "https://cdn.filestackcontent.com/evjJHz9CRcqVjjlK7sf7",
              smallImageSrc: "https://cdn.filestackcontent.com/sAg279WWR4ylvZVUDzhd",
              linkTitle: "Shop now",
              linkConfiguration: {
                route: /* SearchPreloved */25,
                a11yLabel: "Shop preloved now"
              },
              onImageElement: (function (size) {
                  return React.createElement(Ui__HeroHeart.make, {
                              size: size
                            });
                }),
              bulletIcon: (function (size) {
                  return React.createElement(Ui__MarkotIcon.make, {
                              name: "heart-alt-fill",
                              size: size,
                              color: theme.colors.onPrimary
                            });
                }),
              backgroundColor: theme.colors.primaryVariantShade1
            });
}

var match$5 = Ui__Styling.describe({
      imageContainer: {
        TAG: /* Dual */2,
        _0: {
          alignItems: "flex-start",
          flexDirection: "row",
          height: 617
        },
        _1: (function (theme) {
            return {
                    padding: theme.spacing._14
                  };
          })
      },
      headingContainer: {
        TAG: /* Dual */2,
        _0: {
          alignItems: "center",
          justifyContent: "center"
        },
        _1: (function (theme) {
            return {
                    backgroundColor: theme.colors.secondary,
                    padding: theme.spacing._8,
                    paddingBottom: 30
                  };
          })
      },
      title: {
        TAG: /* Static */0,
        _0: {
          fontSize: 30,
          lineHeight: 40,
          textAlign: "center",
          maxWidth: 300
        }
      },
      subtitle: {
        TAG: /* Dual */2,
        _0: {
          fontSize: 16,
          fontWeight: "200",
          textAlign: "center"
        },
        _1: (function (theme) {
            return {
                    marginTop: 0,
                    padding: theme.spacing._10
                  };
          })
      },
      linkText: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    color: theme.colors.primary
                  };
          })
      },
      linkStyle: {
        TAG: /* Static */0,
        _0: {
          flex: 1
        }
      },
      bold: {
        TAG: /* Static */0,
        _0: {
          fontSize: 16,
          fontWeight: "600"
        }
      }
    });

var stylesheet$5 = match$5[1];

var styles$5 = match$5[0];

function Ui__DiscoverHeader$HeaderWithImageAndLink(Props) {
  var backgroundColor = Props.backgroundColor;
  var title = Props.title;
  var subtitle = Props.subtitle;
  var subtitleWithBold = Props.subtitleWithBold;
  var linkConfiguration = Props.linkConfiguration;
  var linkTitle = Props.linkTitle;
  var smallImageSrc = Props.smallImageSrc;
  var largeImageSrc = Props.largeImageSrc;
  var titleStyle = Props.titleStyle;
  var subtitleStyle = Props.subtitleStyle;
  var linkContainerStyle = Props.linkContainerStyle;
  var buttonKindOpt = Props.buttonKind;
  var buttonKind = buttonKindOpt !== undefined ? buttonKindOpt : "primary";
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet$5, theme);
  var smallScreenImageHeight = useSmallScreenHeight(undefined);
  var screenSize = useScreenSize(undefined);
  if (screenSize === "small") {
    var tmp = {
      children: React.createElement(Ui__PlatformLink.make, {
            linkConfiguration: linkConfiguration,
            children: null
          }, React.createElement(ReactNative.View, {
                style: [{
                    height: smallScreenImageHeight - 2
                  }],
                children: React.createElement(Ui__MarkotImage.make, {
                      image: {
                        src: smallImageSrc,
                        width: 1348,
                        height: 617
                      },
                      alt: imageAlt,
                      size: {
                        NAME: "fixedHeightAutoWidth",
                        VAL: {
                          height: smallScreenImageHeight,
                          sizes: "100vw"
                        }
                      },
                      resizeMode: "cover"
                    })
              }), React.createElement(ReactNative.View, {
                style: [
                  Curry._1(styled, styles$5.headingContainer),
                  {
                    backgroundColor: backgroundColor
                  }
                ],
                children: null
              }, React.createElement(Ui__Heading.make, {
                    fontFamily: theme.fontFamily.libre,
                    size: "title2",
                    level: 2,
                    children: title,
                    style: [
                      Caml_option.some(Curry._1(styled, styles$5.title)),
                      titleStyle
                    ]
                  }), React.createElement(Ui__Heading.make, {
                    fontFamily: theme.fontFamily.dm,
                    size: "subtitle",
                    level: 2,
                    children: null,
                    style: [
                      Caml_option.some(Curry._1(styled, styles$5.subtitle)),
                      subtitleStyle
                    ]
                  }, subtitle, " ", subtitleWithBold !== undefined ? React.createElement(Ui__Heading.make, {
                          fontFamily: theme.fontFamily.dm,
                          size: "title2",
                          level: 2,
                          children: React.createElement(ReactNative.Text, {
                                children: subtitleWithBold,
                                style: Curry._1(styled, styles$5.bold)
                              }),
                          style: [
                            Caml_option.some(Curry._1(styled, styles$5.bold)),
                            subtitleStyle
                          ]
                        }) : null), React.createElement(Ui__LinkButton.make, {
                    kind: buttonKind,
                    routeConfiguration: linkConfiguration,
                    title: linkTitle,
                    containerStyle: [
                      Bool$DecidrIoUtils.thenSome(ReactNative.Platform.OS !== "web", Curry._1(styled, styles$5.linkStyle)),
                      linkContainerStyle
                    ],
                    textStyles: Curry._1(styled, styles$5.linkText)
                  })))
    };
    var tmp$1 = Utils__A11y.toSafeA11yRole("complementary");
    if (tmp$1 !== undefined) {
      tmp.accessibilityRole = Caml_option.valFromOption(tmp$1);
    }
    return React.createElement(ReactNative.View, tmp);
  }
  var tmp$2 = {
    children: React.createElement(React.Fragment, undefined),
    containerStyle: [Caml_option.some(Curry._1(styled, styles$5.imageContainer))],
    accessibilityLabel: imageAlt,
    imageBackground: {
      image: {
        src: largeImageSrc,
        width: 1348,
        height: 617
      },
      resizeMode: "cover"
    },
    resizeMode: "cover",
    sizes: "90vw",
    priority: "high"
  };
  var tmp$3 = Utils__A11y.toSafeA11yRole("complementary");
  if (tmp$3 !== undefined) {
    tmp$2.accessibilityRole = Caml_option.valFromOption(tmp$3);
  }
  return React.createElement(Ui__DiscoverHeader$LargeScreenContainer, {
              children: React.createElement(Ui__PlatformLink.make, {
                    linkConfiguration: linkConfiguration,
                    children: React.createElement(Ui__Group.make, tmp$2)
                  })
            });
}

var match$6 = Ui__Styling.describe({
      title: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    color: theme.colors.backgroundVariant,
                    marginTop: 5
                  };
          })
      },
      subtitle: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    color: theme.colors.backgroundVariant
                  };
          })
      },
      linkContainer: {
        TAG: /* Static */0,
        _0: {
          marginTop: 10
        }
      }
    });

var stylesheet$6 = match$6[1];

var styles$6 = match$6[0];

function Ui__DiscoverHeader$SalePromo(Props) {
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet$6, theme);
  return React.createElement(Ui__DiscoverHeader$HeaderWithImageAndLink, {
              backgroundColor: theme.colors.primary,
              title: "20% off all refurbished Bugaboo",
              subtitle: "USE CODE",
              subtitleWithBold: "BUGABOO20",
              linkConfiguration: {
                route: {
                  TAG: /* Search */18,
                  searchTerm: "refurbished bugaboo",
                  loadDataInitially: true,
                  sellingNewItems: undefined,
                  brand: "Bugaboo",
                  category: undefined,
                  productType: "Refurbished"
                },
                a11yLabel: "Sale Promo Bugaboo"
              },
              linkTitle: "SHOP NOW",
              smallImageSrc: "https://cdn.filestackcontent.com/oiJhK89TRauXEIWieWSK",
              largeImageSrc: "https://cdn.filestackcontent.com/PMAGdbSNQCNuF15HHHzA",
              titleStyle: Curry._1(styled, styles$6.title),
              subtitleStyle: Curry._1(styled, styles$6.subtitle),
              linkContainerStyle: Curry._1(styled, styles$6.linkContainer),
              buttonKind: "secondary"
            });
}

var match$7 = Ui__Styling.describe({
      imageContainer: {
        TAG: /* Dual */2,
        _0: {
          alignItems: "flex-start",
          flexDirection: "row",
          height: 617
        },
        _1: (function (theme) {
            return {
                    padding: theme.spacing._14
                  };
          })
      },
      headingContainer: {
        TAG: /* Dual */2,
        _0: {
          alignItems: "center",
          justifyContent: "center"
        },
        _1: (function (theme) {
            return {
                    backgroundColor: theme.colors.secondary,
                    padding: theme.spacing._8,
                    paddingBottom: 30
                  };
          })
      },
      title: {
        TAG: /* Static */0,
        _0: {
          fontSize: 30,
          lineHeight: 40,
          textAlign: "center",
          maxWidth: 300
        }
      },
      subtitle: {
        TAG: /* Dual */2,
        _0: {
          fontSize: 16,
          fontWeight: "200",
          textAlign: "center"
        },
        _1: (function (theme) {
            return {
                    marginTop: 0,
                    padding: theme.spacing._10
                  };
          })
      },
      linkText: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    color: theme.colors.primary
                  };
          })
      },
      linkStyle: {
        TAG: /* Static */0,
        _0: {
          flex: 1
        }
      },
      bold: {
        TAG: /* Static */0,
        _0: {
          fontSize: 16,
          fontWeight: "600"
        }
      }
    });

var stylesheet$7 = match$7[1];

var styles$7 = match$7[0];

function Ui__DiscoverHeader$HeaderWithImage(Props) {
  var linkConfiguration = Props.linkConfiguration;
  var smallImageSrc = Props.smallImageSrc;
  var largeImageSrc = Props.largeImageSrc;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var styled = Curry._1(stylesheet$7, match.theme);
  useSmallScreenHeight(undefined);
  var screenSize = useScreenSizeOnlyImage(undefined);
  if (screenSize === "xxs" || screenSize === "xs" || screenSize === "s") {
    var tmp = {
      children: React.createElement(Ui__PlatformLink.make, {
            linkConfiguration: linkConfiguration,
            children: React.createElement(ReactNative.View, {
                  children: React.createElement(Ui__MarkotImage.make, {
                        image: {
                          src: smallImageSrc,
                          width: 1348,
                          height: 450
                        },
                        alt: imageAlt,
                        size: {
                          NAME: "fixedHeightAutoWidth",
                          VAL: {
                            height: 450,
                            sizes: "100vw"
                          }
                        }
                      })
                })
          })
    };
    var tmp$1 = Utils__A11y.toSafeA11yRole("complementary");
    if (tmp$1 !== undefined) {
      tmp.accessibilityRole = Caml_option.valFromOption(tmp$1);
    }
    return React.createElement(ReactNative.View, tmp);
  }
  var tmp$2 = {
    children: React.createElement(React.Fragment, undefined),
    containerStyle: [Caml_option.some(Curry._1(styled, styles$7.imageContainer))],
    accessibilityLabel: imageAlt,
    imageBackground: {
      image: {
        src: largeImageSrc,
        width: 1348,
        height: 617
      },
      resizeMode: "cover"
    },
    resizeMode: screenSize === "m" ? "contain" : "cover",
    sizes: "90vw",
    priority: "high"
  };
  var tmp$3 = Utils__A11y.toSafeA11yRole("complementary");
  if (tmp$3 !== undefined) {
    tmp$2.accessibilityRole = Caml_option.valFromOption(tmp$3);
  }
  return React.createElement(Ui__DiscoverHeader$LargeScreenContainer, {
              children: React.createElement(Ui__PlatformLink.make, {
                    linkConfiguration: linkConfiguration,
                    children: React.createElement(Ui__Group.make, tmp$2)
                  })
            });
}

var match$8 = Ui__Styling.describe({
      title: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    color: theme.colors.backgroundVariant,
                    marginTop: 5
                  };
          })
      },
      subtitle: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    color: theme.colors.backgroundVariant
                  };
          })
      },
      linkContainer: {
        TAG: /* Static */0,
        _0: {
          marginTop: 10
        }
      }
    });

var stylesheet$8 = match$8[1];

var styles$8 = match$8[0];

function Ui__DiscoverHeader$AfterPayBannerFirst(Props) {
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet$8, theme);
  return React.createElement(Ui__DiscoverHeader$HeaderWithImage, {
              backgroundColor: theme.colors.primary,
              title: "",
              subtitle: "",
              subtitleWithBold: undefined,
              linkConfiguration: {
                route: {
                  TAG: /* Search */18,
                  searchTerm: undefined,
                  loadDataInitially: true,
                  sellingNewItems: undefined,
                  brand: undefined,
                  category: undefined,
                  productType: "Overstocked"
                },
                a11yLabel: "Overstocked Afterpay"
              },
              linkTitle: "",
              smallImageSrc: "https://cdn.filestackcontent.com/nKMmUXVRs2Apmkx2tejQ",
              largeImageSrc: "https://cdn.filestackcontent.com/wCNF1oW2QIaCVJ1pxBr0",
              titleStyle: Curry._1(styled, styles$8.title),
              subtitleStyle: Curry._1(styled, styles$8.subtitle),
              linkContainerStyle: Curry._1(styled, styles$8.linkContainer),
              buttonKind: "secondary"
            });
}

var match$9 = Ui__Styling.describe({
      title: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    color: theme.colors.backgroundVariant,
                    marginTop: 5
                  };
          })
      },
      subtitle: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    color: theme.colors.backgroundVariant
                  };
          })
      },
      linkContainer: {
        TAG: /* Static */0,
        _0: {
          marginTop: 10
        }
      }
    });

var stylesheet$9 = match$9[1];

var styles$9 = match$9[0];

function Ui__DiscoverHeader$AfterPayBannerSecond(Props) {
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet$9, theme);
  return React.createElement(Ui__DiscoverHeader$HeaderWithImage, {
              backgroundColor: theme.colors.primary,
              title: "",
              subtitle: "",
              subtitleWithBold: undefined,
              linkConfiguration: {
                route: {
                  TAG: /* Search */18,
                  searchTerm: undefined,
                  loadDataInitially: true,
                  sellingNewItems: undefined,
                  brand: undefined,
                  category: undefined,
                  productType: "Overstocked"
                },
                a11yLabel: "Overstocked Afterpay"
              },
              linkTitle: "",
              smallImageSrc: "https://cdn.filestackcontent.com/PPssloiISNax9dhp2ajT",
              largeImageSrc: "https://cdn.filestackcontent.com/fDfw1Q5ORlSI1Cx1l4fr",
              titleStyle: Curry._1(styled, styles$9.title),
              subtitleStyle: Curry._1(styled, styles$9.subtitle),
              linkContainerStyle: Curry._1(styled, styles$9.linkContainer),
              buttonKind: "secondary"
            });
}

var match$10 = Ui__Styling.describe({
      title: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    color: theme.colors.backgroundVariant,
                    marginTop: 5
                  };
          })
      },
      subtitle: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    color: theme.colors.backgroundVariant
                  };
          })
      },
      linkContainer: {
        TAG: /* Static */0,
        _0: {
          marginTop: 10
        }
      }
    });

var stylesheet$10 = match$10[1];

var styles$10 = match$10[0];

function Ui__DiscoverHeader$AfterPayBannerThird(Props) {
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet$10, theme);
  return React.createElement(Ui__DiscoverHeader$HeaderWithImage, {
              backgroundColor: theme.colors.primary,
              title: "",
              subtitle: "",
              subtitleWithBold: undefined,
              linkConfiguration: {
                route: {
                  TAG: /* Search */18,
                  searchTerm: undefined,
                  loadDataInitially: true,
                  sellingNewItems: undefined,
                  brand: undefined,
                  category: undefined,
                  productType: "Overstocked"
                },
                a11yLabel: "Overstocked Afterpay"
              },
              linkTitle: "",
              smallImageSrc: "https://cdn.filestackcontent.com/tLmjuqbESCyj0ygK8YBz",
              largeImageSrc: "https://cdn.filestackcontent.com/EzA4yIQ6RNqsZEnuXa5w",
              titleStyle: Curry._1(styled, styles$10.title),
              subtitleStyle: Curry._1(styled, styles$10.subtitle),
              linkContainerStyle: Curry._1(styled, styles$10.linkContainer),
              buttonKind: "secondary"
            });
}

var match$11 = Ui__Styling.describe({
      title: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    color: theme.colors.backgroundVariant,
                    marginTop: 5
                  };
          })
      },
      subtitle: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    color: theme.colors.backgroundVariant
                  };
          })
      },
      linkContainer: {
        TAG: /* Static */0,
        _0: {
          marginTop: 10
        }
      }
    });

var stylesheet$11 = match$11[1];

var styles$11 = match$11[0];

function Ui__DiscoverHeader$WareHouseBannerFrist(Props) {
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet$11, theme);
  return React.createElement(Ui__DiscoverHeader$HeaderWithImage, {
              backgroundColor: theme.colors.primary,
              title: "",
              subtitle: "",
              subtitleWithBold: undefined,
              linkConfiguration: {
                route: /* Competition */30,
                a11yLabel: "Competition Page"
              },
              linkTitle: "",
              smallImageSrc: "https://cdn.filestackcontent.com/4905rvu2Q3GBT8clXNgE",
              largeImageSrc: "https://cdn.filestackcontent.com/4905rvu2Q3GBT8clXNgE",
              titleStyle: Curry._1(styled, styles$11.title),
              subtitleStyle: Curry._1(styled, styles$11.subtitle),
              linkContainerStyle: Curry._1(styled, styles$11.linkContainer),
              buttonKind: "secondary"
            });
}

var match$12 = Ui__Styling.describe({
      title: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    color: theme.colors.backgroundVariant,
                    marginTop: 5
                  };
          })
      },
      subtitle: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    color: theme.colors.backgroundVariant
                  };
          })
      },
      linkContainer: {
        TAG: /* Static */0,
        _0: {
          marginTop: 10
        }
      }
    });

var stylesheet$12 = match$12[1];

var styles$12 = match$12[0];

function Ui__DiscoverHeader$WareHouseBannerSecond(Props) {
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet$12, theme);
  return React.createElement(Ui__DiscoverHeader$HeaderWithImage, {
              backgroundColor: theme.colors.primary,
              title: "",
              subtitle: "",
              subtitleWithBold: undefined,
              linkConfiguration: {
                route: /* Competition */30,
                a11yLabel: "Competition Page"
              },
              linkTitle: "",
              smallImageSrc: "https://cdn.filestackcontent.com/T2DJP8u7TIeJ9yL1zilx",
              largeImageSrc: "https://cdn.filestackcontent.com/UxgSFqUARryT40nnsAnA",
              titleStyle: Curry._1(styled, styles$12.title),
              subtitleStyle: Curry._1(styled, styles$12.subtitle),
              linkContainerStyle: Curry._1(styled, styles$12.linkContainer),
              buttonKind: "secondary"
            });
}

var match$13 = Ui__Styling.describe({
      title: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    color: theme.colors.backgroundVariant,
                    marginTop: 5
                  };
          })
      },
      subtitle: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    color: theme.colors.backgroundVariant
                  };
          })
      },
      linkContainer: {
        TAG: /* Static */0,
        _0: {
          marginTop: 10
        }
      }
    });

var stylesheet$13 = match$13[1];

var styles$13 = match$13[0];

function Ui__DiscoverHeader$WareHouseBannerThird(Props) {
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet$13, theme);
  return React.createElement(Ui__DiscoverHeader$HeaderWithImage, {
              backgroundColor: theme.colors.primary,
              title: "",
              subtitle: "",
              subtitleWithBold: undefined,
              linkConfiguration: {
                route: {
                  TAG: /* ExternalRoute */12,
                  url: "https://www.eventbrite.com/e/markot-friends-3-day-warehouse-event-tickets-705716034657?aff=oddtdtcreator",
                  target: "_blank"
                },
                a11yLabel: "Warehoust Event"
              },
              linkTitle: "",
              smallImageSrc: "https://cdn.filestackcontent.com/6Gjlve8TkuQf2IRzF48a",
              largeImageSrc: "https://cdn.filestackcontent.com/GgaMSoWgReGkAwAYOL7X",
              titleStyle: Curry._1(styled, styles$13.title),
              subtitleStyle: Curry._1(styled, styles$13.subtitle),
              linkContainerStyle: Curry._1(styled, styles$13.linkContainer),
              buttonKind: "secondary"
            });
}

var make = Ui__DiscoverHeader;

var DiscountCodePromo = {
  DiscountNotice: DiscountNotice,
  make: Ui__DiscoverHeader$DiscountCodePromo
};

var Refurbished = {
  make: Ui__DiscoverHeader$Refurbished
};

var Overstocked = {
  make: Ui__DiscoverHeader$Overstocked
};

var Preloved = {
  make: Ui__DiscoverHeader$Preloved
};

var SalePromo = {
  make: Ui__DiscoverHeader$SalePromo
};

var AfterPayBannerFirst = {
  make: Ui__DiscoverHeader$AfterPayBannerFirst
};

var AfterPayBannerSecond = {
  make: Ui__DiscoverHeader$AfterPayBannerSecond
};

var AfterPayBannerThird = {
  make: Ui__DiscoverHeader$AfterPayBannerThird
};

var WareHouseBannerFrist = {
  make: Ui__DiscoverHeader$WareHouseBannerFrist
};

var WareHouseBannerSecond = {
  make: Ui__DiscoverHeader$WareHouseBannerSecond
};

var WareHouseBannerThird = {
  make: Ui__DiscoverHeader$WareHouseBannerThird
};

export {
  make ,
  useSmallScreenHeight ,
  useScreenSize ,
  DiscountCodePromo ,
  Refurbished ,
  Overstocked ,
  Preloved ,
  SalePromo ,
  AfterPayBannerFirst ,
  AfterPayBannerSecond ,
  AfterPayBannerThird ,
  WareHouseBannerFrist ,
  WareHouseBannerSecond ,
  WareHouseBannerThird ,
}
/* match Not a pure module */
