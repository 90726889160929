// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Group from "../../group/components/Ui__Group.bs.js";
import * as Ui__Theme from "../../theme/components/Ui__Theme.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../../styling/Ui__Styling.bs.js";
import * as ReactNative from "react-native";
import * as Ui__LinkButton from "../../linkButton/components/Ui__LinkButton.bs.js";
import * as Ui__MarkotText from "../../text/components/Ui__MarkotText.bs.js";
import * as Ui__Responsive from "../../responsive/Ui__Responsive.bs.js";
import * as Ui__MarkotImage from "../../image/components/Ui__MarkotImage.bs.js";
import * as Ui__PlatformLink from "../../routing/Ui__PlatformLink.bs.js";
import * as Ui__MultilineText from "../../multipleLines/components/Ui__MultilineText.bs.js";
import * as Bool$DecidrIoUtils from "@decidr-io/utils/src/bool/Bool.bs.js";
import * as SimpleReact$DecidrIoUtils from "@decidr-io/utils/src/react/SimpleReact.bs.js";

function useCardHeight(param) {
  return Curry.app(Ui__Responsive.useMedia(undefined)(undefined).mediaSelect, [
              undefined,
              undefined,
              222,
              260,
              294,
              326,
              undefined,
              189,
              undefined
            ]);
}

var match = Ui__Styling.describe({
      container: {
        TAG: /* Dual */2,
        _0: {
          flexDirection: "row"
        },
        _1: (function (param) {
            return {
                    height: param.cardHeight,
                    paddingHorizontal: param.theme.sectionSpacing._05
                  };
          })
      },
      cardBackground: {
        TAG: /* Dual */2,
        _0: {
          alignItems: "center",
          flex: 2,
          justifyContent: "center"
        },
        _1: (function (param) {
            return {
                    backgroundColor: param.defaultBackgroundColor
                  };
          })
      },
      image: {
        TAG: /* Dynamic */1,
        _0: (function (param) {
            return {
                    flex: param.screenSize === "small" ? 1 : 2,
                    height: param.cardHeight
                  };
          })
      },
      buttonBackground: {
        TAG: /* Dynamic */1,
        _0: (function (param) {
            return {
                    borderColor: param.theme.colors.secondaryVariant2
                  };
          })
      },
      buttonWrapper: {
        TAG: /* Static */0,
        _0: {
          flexGrow: 0
        }
      },
      buttonText: {
        TAG: /* Dynamic */1,
        _0: (function (param) {
            return {
                    color: param.theme.colors.secondaryVariant2
                  };
          })
      },
      dropShadow: {
        TAG: /* Dynamic */1,
        _0: (function (param) {
            var screenSize = param.screenSize;
            return {
                    shadowColor: param.theme.colors.primary,
                    shadowOffset: {
                      height: 1,
                      width: 0
                    },
                    shadowOpacity: screenSize === "small" ? 0.07 : 0.35,
                    shadowRadius: screenSize === "small" ? 4 : 2
                  };
          })
      }
    });

var stylesheet = match[1];

var styles = match[0];

function Ui__NewPromoCarouselCard(Props) {
  var backgroundColor = Props.backgroundColor;
  var textLines = Props.textLines;
  var foregroundColor = Props.foregroundColor;
  var images = Props.images;
  var alt = Props.alt;
  var buttonKindOpt = Props.buttonKind;
  var buttonTitle = Props.buttonTitle;
  var routeConfiguration = Props.routeConfiguration;
  var testIDOpt = Props.testID;
  var priority = Props.priority;
  var contentUpRightOpt = Props.contentUpRight;
  var contentDownRightOpt = Props.contentDownRight;
  var spacingGroupElement = Props.spacingGroupElement;
  var titleStyle = Props.titleStyle;
  var buttonKind = buttonKindOpt !== undefined ? buttonKindOpt : "secondaryOutline";
  var testID = testIDOpt !== undefined ? testIDOpt : "new-promo-carousel-card";
  var contentUpRight = contentUpRightOpt !== undefined ? Caml_option.valFromOption(contentUpRightOpt) : null;
  var contentDownRight = contentDownRightOpt !== undefined ? Caml_option.valFromOption(contentDownRightOpt) : null;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var cardHeight = useCardHeight(undefined);
  var screenSize = Curry.app(Ui__Responsive.useMedia(undefined)(undefined).mediaSelect, [
        undefined,
        undefined,
        undefined,
        undefined,
        "large",
        undefined,
        undefined,
        "small",
        undefined
      ]);
  var defaultBackgroundColor = Belt_Option.getWithDefault(backgroundColor, theme.colors.primary);
  var styled = Curry._1(stylesheet, {
        theme: theme,
        cardHeight: cardHeight,
        screenSize: screenSize,
        defaultBackgroundColor: defaultBackgroundColor
      });
  var widthLimiterStyle = Ui__Responsive.widthLimiterStyle(undefined, 1536, undefined);
  var tmp = {
    image: screenSize === "small" ? images.smallScreen : images.largeScreen,
    alt: alt,
    size: {
      NAME: "fixedHeightAutoWidth",
      VAL: {
        height: cardHeight,
        sizes: "((min-width: 320px) and (max-width: 1024px)) 30vw, (min-width: 1024px) 50vw"
      }
    },
    style: Curry._1(styled, styles.image),
    testID: "card-image"
  };
  if (priority !== undefined) {
    tmp.priority = Caml_option.valFromOption(priority);
  }
  var tmp$1 = {
    kind: buttonKind,
    routeConfiguration: routeConfiguration,
    title: buttonTitle,
    containerStyle: [
      Bool$DecidrIoUtils.thenSome(buttonKind === "secondaryOutline", Curry._1(styled, styles.buttonBackground)),
      Bool$DecidrIoUtils.thenSome(buttonKind === "secondary", Curry._1(styled, styles.dropShadow))
    ],
    buttonElementsWrapperStyle: Curry._1(styled, styles.buttonWrapper)
  };
  var tmp$2 = Bool$DecidrIoUtils.thenSome(buttonKind === "secondaryOutline", Curry._1(styled, styles.buttonText));
  if (tmp$2 !== undefined) {
    tmp$1.textStyles = Caml_option.valFromOption(tmp$2);
  }
  return React.createElement(Ui__PlatformLink.make, {
              linkConfiguration: routeConfiguration,
              children: React.createElement(ReactNative.View, {
                    style: [
                      Curry._1(styled, styles.container),
                      widthLimiterStyle
                    ],
                    testID: testID,
                    children: null
                  }, React.createElement(Ui__MarkotImage.make, tmp), React.createElement(Ui__Group.make, {
                        spacing: spacingGroupElement,
                        children: null,
                        containerStyle: Curry._1(styled, styles.cardBackground),
                        testID: "text-and-button-container"
                      }, contentUpRight, React.createElement(Ui__MultilineText.make, {
                            textLines: textLines,
                            color: foregroundColor,
                            textStyle: [titleStyle],
                            spacing: 0,
                            size: "title2",
                            fontFamily: "libre"
                          }), contentDownRight, React.createElement(Ui__LinkButton.make, tmp$1)))
            });
}

function Ui__NewPromoCarouselCard$Tag(Props) {
  var text = Props.text;
  var match = Curry._1(Ui__Theme.useTheme, undefined);
  var theme = match.theme;
  return React.createElement(ReactNative.View, {
              style: {
                backgroundColor: "#E7E5D9",
                borderRadius: theme.roundness._3,
                borderWidth: theme.lineThickness.s,
                shadowColor: "#000000",
                shadowOffset: {
                  height: 2,
                  width: 2
                },
                shadowRadius: 1,
                left: 0,
                marginLeft: theme.spacing._8,
                marginTop: theme.spacing._8,
                paddingHorizontal: theme.spacing._3,
                paddingVertical: theme.spacing._4,
                position: "absolute",
                top: 0,
                zIndex: 10
              },
              children: React.createElement(Ui__MarkotText.make, {
                    fontFamily: theme.fontFamily.dm,
                    size: "b2",
                    children: text,
                    style: {
                      fontWeight: "bold"
                    }
                  })
            });
}

var Tag = {
  make: Ui__NewPromoCarouselCard$Tag
};

var match$1 = Ui__Styling.describe({
      container: {
        TAG: /* Dynamic */1,
        _0: (function (param) {
            return {
                    height: param.cardHeight,
                    paddingHorizontal: param.theme.sectionSpacing._05
                  };
          })
      },
      wrapper: {
        TAG: /* Static */0,
        _0: {
          flexDirection: "row"
        }
      },
      cardBackground: {
        TAG: /* Dual */2,
        _0: {
          alignItems: "center",
          flex: 2,
          justifyContent: "center"
        },
        _1: (function (param) {
            return {
                    backgroundColor: param.defaultBackgroundColor
                  };
          })
      },
      image: {
        TAG: /* Dynamic */1,
        _0: (function (param) {
            return {
                    flex: param.screenSize === "small" ? 1 : 2,
                    height: param.cardHeight
                  };
          })
      },
      buttonBackground: {
        TAG: /* Dynamic */1,
        _0: (function (param) {
            return {
                    borderColor: param.theme.colors.secondaryVariant2
                  };
          })
      },
      buttonWrapper: {
        TAG: /* Static */0,
        _0: {
          flexGrow: 0
        }
      },
      buttonText: {
        TAG: /* Dynamic */1,
        _0: (function (param) {
            return {
                    color: param.theme.colors.secondaryVariant2
                  };
          })
      },
      dropShadow: {
        TAG: /* Dynamic */1,
        _0: (function (param) {
            var screenSize = param.screenSize;
            return {
                    shadowColor: param.theme.colors.primary,
                    shadowOffset: {
                      height: 1,
                      width: 0
                    },
                    shadowOpacity: screenSize === "small" ? 0.07 : 0.35,
                    shadowRadius: screenSize === "small" ? 4 : 2
                  };
          })
      }
    });

var stylesheet$1 = match$1[1];

var styles$1 = match$1[0];

function Ui__NewPromoCarouselCard$Extensible(Props) {
  var backgroundColor = Props.backgroundColor;
  var textLines = Props.textLines;
  var foregroundColor = Props.foregroundColor;
  var images = Props.images;
  var alt = Props.alt;
  var buttonKindOpt = Props.buttonKind;
  var buttonTitle = Props.buttonTitle;
  var buttonColor = Props.buttonColor;
  var buttonTextColor = Props.buttonTextColor;
  var routeConfiguration = Props.routeConfiguration;
  var testIDOpt = Props.testID;
  var priority = Props.priority;
  var additionalContentUp = Props.additionalContentUp;
  var additionalContentDown = Props.additionalContentDown;
  var additionalContentBelowTitle = Props.additionalContentBelowTitle;
  var buttonKind = buttonKindOpt !== undefined ? buttonKindOpt : "secondaryOutline";
  var testID = testIDOpt !== undefined ? testIDOpt : "new-promo-carousel-card";
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var cardHeight = useCardHeight(undefined);
  var screenSize = Curry.app(Ui__Responsive.useMedia(undefined)(undefined).mediaSelect, [
        undefined,
        undefined,
        undefined,
        undefined,
        "large",
        undefined,
        undefined,
        "small",
        undefined
      ]);
  var defaultBackgroundColor = Belt_Option.getWithDefault(backgroundColor, theme.colors.primary);
  var styled = Curry._1(stylesheet$1, {
        theme: theme,
        cardHeight: cardHeight,
        screenSize: screenSize,
        defaultBackgroundColor: defaultBackgroundColor
      });
  var widthLimiterStyle = Ui__Responsive.widthLimiterStyle(undefined, 1536, undefined);
  var tmp = {
    image: screenSize === "small" ? images.smallScreen : images.largeScreen,
    alt: alt,
    size: {
      NAME: "fixedHeightAutoWidth",
      VAL: {
        height: cardHeight,
        sizes: "((min-width: 320px) and (max-width: 1024px)) 30vw, (min-width: 1024px) 50vw"
      }
    },
    style: Curry._1(styled, styles$1.image),
    testID: "card-image"
  };
  if (priority !== undefined) {
    tmp.priority = Caml_option.valFromOption(priority);
  }
  return React.createElement(ReactNative.View, {
              style: [
                Curry._1(styled, styles$1.container),
                widthLimiterStyle
              ],
              testID: testID,
              children: React.createElement(ReactNative.View, {
                    style: Curry._1(styled, styles$1.wrapper),
                    children: null
                  }, SimpleReact$DecidrIoUtils.renderIfSome(additionalContentUp, (function (additionalContentUp) {
                          return additionalContentUp;
                        })), React.createElement(Ui__MarkotImage.make, tmp), React.createElement(Ui__Group.make, {
                        spacing: theme.sectionSpacing._1,
                        children: null,
                        containerStyle: Curry._1(styled, styles$1.cardBackground),
                        testID: "text-and-button-container"
                      }, React.createElement(ReactNative.View, {
                            children: null
                          }, React.createElement(Ui__MultilineText.make, {
                                textLines: textLines,
                                color: foregroundColor,
                                spacing: 0,
                                size: "title2",
                                fontFamily: "libre"
                              }), SimpleReact$DecidrIoUtils.renderIfSome(additionalContentBelowTitle, (function (additionalContentBelowTitle) {
                                  return additionalContentBelowTitle;
                                }))), React.createElement(Ui__LinkButton.make, {
                            kind: buttonKind,
                            routeConfiguration: routeConfiguration,
                            title: buttonTitle,
                            containerStyle: [
                              Bool$DecidrIoUtils.thenSome(buttonKind === "secondaryOutline", Curry._1(styled, styles$1.buttonBackground)),
                              Bool$DecidrIoUtils.thenSome(buttonKind === "secondary", Curry._1(styled, styles$1.dropShadow)),
                              Belt_Option.map(buttonColor, (function (buttonColor) {
                                      return {
                                              backgroundColor: buttonColor
                                            };
                                    }))
                            ],
                            textStyles: [
                              Bool$DecidrIoUtils.thenSome(buttonKind === "secondaryOutline", Curry._1(styled, styles$1.buttonText)),
                              Belt_Option.map(buttonTextColor, (function (buttonTextColor) {
                                      return {
                                              color: buttonTextColor
                                            };
                                    }))
                            ],
                            buttonElementsWrapperStyle: Curry._1(styled, styles$1.buttonWrapper)
                          })), SimpleReact$DecidrIoUtils.renderIfSome(additionalContentDown, (function (additionalContentDown) {
                          return additionalContentDown;
                        })))
            });
}

var make = Ui__NewPromoCarouselCard;

var Extensible = {
  make: Ui__NewPromoCarouselCard$Extensible
};

export {
  useCardHeight ,
  make ,
  Tag ,
  Extensible ,
}
/* match Not a pure module */
