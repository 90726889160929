// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as App__Config from "../../config/App__Config.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactRelay from "react-relay";
import * as Ui__BlogCard from "../../../../ui/src/blogCard/components/Ui__BlogCard.bs.js";
import * as RescriptRelay_Internal from "rescript-relay/src/RescriptRelay_Internal.bs.js";
import * as AppBlogPostCard_blogPost_graphql from "../../../../relay-codegen/generated/AppBlogPostCard_blogPost_graphql.bs.js";

function use(fRef) {
  var data = ReactRelay.useFragment(AppBlogPostCard_blogPost_graphql.node, fRef);
  return RescriptRelay_Internal.internal_useConvertedValue(AppBlogPostCard_blogPost_graphql.Internal.convertFragment, data);
}

function App__BlogPost__Card(Props) {
  var blogPost = Props.blogPost;
  var style = Props.style;
  var imageSizes = Props.imageSizes;
  var testIDOpt = Props.testID;
  var testID = testIDOpt !== undefined ? testIDOpt : "blog-post-card";
  var data = use(blogPost);
  var maybeImage = data.coverImage;
  var tmp;
  if (maybeImage !== undefined) {
    var size = maybeImage.size;
    tmp = {
      src: maybeImage.url,
      width: size.width,
      height: size.height
    };
  } else {
    tmp = undefined;
  }
  var tmp$1 = {
    image: tmp,
    title: data.title,
    behavior: {
      NAME: "ExternalLink",
      VAL: {
        link: {
          route: {
            TAG: /* ExternalRoute */12,
            url: data.url,
            target: undefined
          },
          a11yLabel: "Read more about " + data.title + ""
        },
        baseUrl: App__Config.values.webRootUrl
      }
    },
    buttonText: "Read more...",
    alt: "" + data.title + " - Image",
    testID: testID
  };
  if (data.blurb !== undefined) {
    tmp$1.description = data.blurb;
  }
  if (style !== undefined) {
    tmp$1.style = Caml_option.valFromOption(style);
  }
  if (imageSizes !== undefined) {
    tmp$1.imageSizes = imageSizes;
  }
  return React.createElement(Ui__BlogCard.make, tmp$1);
}

var make = App__BlogPost__Card;

export {
  make ,
}
/* react Not a pure module */
