// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__AppLink from "./Ui__AppLink.bs.js";
import * as ReactNative from "react-native";

function Ui__PlatformLink(Props) {
  var linkConfiguration = Props.linkConfiguration;
  var children = Props.children;
  var match = Curry._1(Ui__AppLink.useRouting, undefined);
  if (ReactNative.Platform.OS === "web") {
    return React.createElement(Ui__AppLink.make, {
                configuration: Curry._3(Ui__AppLink.makeConfiguration, linkConfiguration.route, undefined, undefined),
                children: children,
                a11yLabel: linkConfiguration.a11yLabel
              });
  }
  var tmp = {
    children: children
  };
  var tmp$1 = Belt_Option.map(match.nativeNavigationHandler, (function (navHandler) {
          var configuration = Curry._3(Ui__AppLink.makeConfiguration, linkConfiguration.route, undefined, undefined);
          return function (param) {
            Curry._1(navHandler, configuration);
          };
        }));
  if (tmp$1 !== undefined) {
    tmp.onPress = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(ReactNative.TouchableOpacity, tmp);
}

var make = Ui__PlatformLink;

export {
  make ,
}
/* react Not a pure module */
