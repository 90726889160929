/* TypeScript file generated from Ui__PageWrapper.resi by genType. */
/* eslint-disable import/first */


import * as React from 'react';

// @ts-ignore: Implicit any on import
import * as Ui__PageWrapperBS__Es6Import from './Ui__PageWrapper.bs';
const Ui__PageWrapperBS: any = Ui__PageWrapperBS__Es6Import;

import type {ScrollView_element as ReactNative_ScrollView_element} from '../../../../../rescript-shims/ReactNative.shim';

import type {Style_t as ReactNative_Style_t} from '../../../../../rescript-shims/ReactNative.shim';

// tslint:disable-next-line:interface-over-type-literal
export type Props = {
  readonly contentContainerStyle?: ReactNative_Style_t; 
  readonly style?: ReactNative_Style_t; 
  readonly onScrolledToTop?: () => void; 
  readonly scrollRef?: {
    current: (null | (null | undefined | ReactNative_ScrollView_element))
  }; 
  readonly isWeb?: boolean; 
  readonly showsVerticalScrollIndicator?: boolean; 
  readonly testID?: string; 
  readonly scrolledToTop?: boolean; 
  readonly refreshControl?: JSX.Element; 
  readonly children: React.ReactNode
};

export const make: React.ComponentType<{
  readonly contentContainerStyle?: ReactNative_Style_t; 
  readonly style?: ReactNative_Style_t; 
  readonly onScrolledToTop?: () => void; 
  readonly scrollRef?: {
    current: (null | (null | undefined | ReactNative_ScrollView_element))
  }; 
  readonly isWeb?: boolean; 
  readonly showsVerticalScrollIndicator?: boolean; 
  readonly testID?: string; 
  readonly scrolledToTop?: boolean; 
  readonly refreshControl?: JSX.Element; 
  readonly children: React.ReactNode
}> = Ui__PageWrapperBS.make;
