// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Group from "../../../group/components/Ui__Group.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../../../styling/Ui__Styling.bs.js";
import * as Utils__A11y from "../../../../../utils/src/Utils__A11y.bs.js";
import * as ReactNative from "react-native";
import * as Ui__LinkButton from "../../../linkButton/components/Ui__LinkButton.bs.js";
import * as Ui__MarkotText from "../../../text/components/Ui__MarkotText.bs.js";
import * as Ui__Responsive from "../../../responsive/Ui__Responsive.bs.js";
import * as Ui__ExperimentalComponentSpacing from "../../../styling/Ui__ExperimentalComponentSpacing.bs.js";

var match = Ui__Styling.describe({
      container: {
        TAG: /* Dual */2,
        _0: {
          alignItems: "center",
          justifyContent: "center"
        },
        _1: (function (theme) {
            return {
                    padding: theme.spacing._10
                  };
          })
      },
      text: {
        TAG: /* Static */0,
        _0: {
          textAlign: "center"
        }
      },
      linkButtonWrapper: {
        TAG: /* Static */0,
        _0: {
          flexGrow: 0
        }
      }
    });

var stylesheet = match[1];

var styles = match[0];

function Ui__Postcard(Props) {
  var title = Props.title;
  var titleColor = Props.titleColor;
  var buttonTitle = Props.buttonTitle;
  var buttonKind = Props.buttonKind;
  var routeConfiguration = Props.routeConfiguration;
  var image = Props.image;
  var imagePriority = Props.imagePriority;
  var imageSizes = Props.imageSizes;
  var imageAlt = Props.imageAlt;
  var style = Props.style;
  var imageBackgroundStyle = Props.imageBackgroundStyle;
  var headingLevel = Props.headingLevel;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet, theme);
  var match$1 = Ui__ExperimentalComponentSpacing.use(undefined);
  var cardHeight = Curry.app(Ui__Responsive.useMedia(undefined)(undefined).mediaSelect, [
        undefined,
        189,
        202,
        231,
        260,
        297,
        316,
        300,
        undefined
      ]);
  var tmp = {
    spacing: match$1.l,
    children: null,
    containerStyle: [
      Caml_option.some(Curry._1(styled, styles.container)),
      {
        height: cardHeight
      },
      style
    ],
    accessibilityLabel: imageAlt,
    accessibilityRole: "article",
    imageBackground: {
      image: image,
      resizeMode: "cover"
    },
    resizeMode: "cover",
    sizes: imageSizes
  };
  if (imageBackgroundStyle !== undefined) {
    tmp.imageBackgroundStyle = Caml_option.valFromOption(imageBackgroundStyle);
  }
  if (imagePriority !== undefined) {
    tmp.priority = Caml_option.valFromOption(imagePriority);
  }
  var tmp$1 = {
    fontFamily: theme.fontFamily.libre,
    size: "title1",
    color: titleColor,
    children: title,
    style: Curry._1(styled, styles.text)
  };
  var tmp$2 = Belt_Option.flatMap(headingLevel, (function (param) {
          return Utils__A11y.toSafeA11yRole("header");
        }));
  if (tmp$2 !== undefined) {
    tmp$1.accessibilityRole = Caml_option.valFromOption(tmp$2);
  }
  if (headingLevel !== undefined) {
    tmp$1.accessibilityLevel = Caml_option.valFromOption(headingLevel);
  }
  return React.createElement(Ui__Group.make, tmp, React.createElement(Ui__MarkotText.make, tmp$1), React.createElement(ReactNative.View, {
                  children: React.createElement(Ui__LinkButton.make, {
                        kind: buttonKind,
                        routeConfiguration: routeConfiguration,
                        title: buttonTitle,
                        buttonElementsWrapperStyle: Curry._1(styled, styles.linkButtonWrapper)
                      })
                }));
}

var make = Ui__Postcard;

export {
  make ,
}
/* match Not a pure module */
