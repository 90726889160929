// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Group from "../../../ui/src/group/components/Ui__Group.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Heading from "../../../ui/src/text/components/Ui__Heading.bs.js";
import * as Ui__Styling from "../../../ui/src/styling/Ui__Styling.bs.js";
import * as ReactRelay from "react-relay";
import * as ReactNative from "react-native";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";
import * as Ui__MarkotIcon from "../../../ui/src/icons/components/Ui__MarkotIcon.bs.js";
import * as Ui__MarkotText from "../../../ui/src/text/components/Ui__MarkotText.bs.js";
import * as Ui__Responsive from "../../../ui/src/responsive/Ui__Responsive.bs.js";
import * as Ui__PageSection from "../../../ui/src/pageSection/components/Ui__PageSection.bs.js";
import * as Bool$DecidrIoUtils from "@decidr-io/utils/src/bool/Bool.bs.js";
import * as App__BlogPost__Card from "../blogPost/card/App__BlogPost__Card.bs.js";
import * as Ui__ListsLoadingView from "../../../ui/src/loading/components/Ui__ListsLoadingView.bs.js";
import * as RescriptRelay_Internal from "rescript-relay/src/RescriptRelay_Internal.bs.js";
import * as Ui__MarkotErrorBoundary from "../../../ui/src/error/markotErrorBoundary/Ui__MarkotErrorBoundary.bs.js";
import * as AppDiscoverBlogPosts_query_graphql from "../../../relay-codegen/generated/AppDiscoverBlogPosts_query_graphql.bs.js";
import * as AppDiscoverBlogPostsPaginationQuery_graphql from "../../../relay-codegen/generated/AppDiscoverBlogPostsPaginationQuery_graphql.bs.js";

var match = Ui__Styling.describe({
      mediumLargeWrapper: {
        TAG: /* Static */0,
        _0: {
          flexDirection: "row",
          justifyContent: "center"
        }
      },
      container: {
        TAG: /* Dynamic */1,
        _0: (function (props) {
            var tmp = {
              flex: 1
            };
            var tmp$1 = Belt_Option.map(props.maxWidth, (function (prim) {
                    return prim;
                  }));
            if (tmp$1 !== undefined) {
              tmp.maxWidth = Caml_option.valFromOption(tmp$1);
            }
            return tmp;
          })
      },
      iconContainer: {
        TAG: /* Dual */2,
        _0: {
          alignItems: "center",
          justifyContent: "center"
        },
        _1: (function (props) {
            return {
                    backgroundColor: props.theme.colors.background,
                    height: props.iconContainerHeight
                  };
          })
      },
      textSection: {
        TAG: /* Dynamic */1,
        _0: (function (props) {
            return {
                    paddingHorizontal: props.paddingHorizontal
                  };
          })
      }
    });

var stylesheet = match[1];

var styles = match[0];

function App__DiscoverBlogPosts$EmptyState(Props) {
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var match$1 = Curry.app(Ui__Responsive.useMedia(undefined)(undefined).mediaSelect, [
        undefined,
        undefined,
        {
          maxWidth: undefined,
          iconContainerHeight: 413,
          mediaSize: "small"
        },
        {
          maxWidth: 372,
          iconContainerHeight: 281,
          mediaSize: "medium"
        },
        {
          maxWidth: 500,
          iconContainerHeight: 426,
          mediaSize: "medium"
        },
        {
          maxWidth: 370,
          iconContainerHeight: 211,
          mediaSize: "large"
        },
        {
          maxWidth: 456,
          iconContainerHeight: 272,
          mediaSize: "large"
        },
        {
          maxWidth: undefined,
          iconContainerHeight: 227,
          mediaSize: "small"
        },
        undefined
      ]);
  var mediaSize = match$1.mediaSize;
  var spacing = mediaSize === "small" || mediaSize === "medium" ? theme.spacing._6 : theme.spacing._10;
  var paddingHorizontal = mediaSize === "medium" ? theme.spacing._12 : theme.spacing._6;
  var styled = Curry._1(stylesheet, {
        theme: theme,
        maxWidth: match$1.maxWidth,
        paddingHorizontal: paddingHorizontal,
        iconContainerHeight: match$1.iconContainerHeight
      });
  var tmp = {
    children: React.createElement(Ui__Group.make, {
          spacing: spacing,
          children: null,
          containerStyle: Curry._1(styled, styles.container),
          orientation: "vertical",
          testID: "blog-posts-empty-state"
        }, React.createElement(ReactNative.View, {
              style: Curry._1(styled, styles.iconContainer),
              children: React.createElement(Ui__MarkotIcon.make, {
                    name: "text-drop-cap",
                    size: 58,
                    color: theme.colors.secondaryVariant2
                  })
            }), React.createElement(Ui__Group.make, {
              spacing: theme.spacing._5,
              children: null,
              containerStyle: Curry._1(styled, styles.textSection)
            }, React.createElement(Ui__Heading.make, {
                  fontFamily: theme.fontFamily.libre,
                  size: "title3",
                  level: 2,
                  children: "No posts to show"
                }), React.createElement(Ui__MarkotText.make, {
                  size: "b2",
                  children: "Try refreshing the page"
                })))
  };
  var tmp$1 = Bool$DecidrIoUtils.thenSome(mediaSize !== "small", Curry._1(styled, styles.mediumLargeWrapper));
  if (tmp$1 !== undefined) {
    tmp.style = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(ReactNative.View, tmp);
}

function internal_makeRefetchableFnOpts(fetchPolicy, onComplete, param) {
  var tmp = {};
  var tmp$1 = RescriptRelay.mapFetchPolicy(fetchPolicy);
  if (tmp$1 !== undefined) {
    tmp.fetchPolicy = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = RescriptRelay_Internal.internal_nullableToOptionalExnHandler(onComplete);
  if (tmp$2 !== undefined) {
    tmp.onComplete = Caml_option.valFromOption(tmp$2);
  }
  return tmp;
}

function usePagination(fr) {
  var p = ReactRelay.usePaginationFragment(AppDiscoverBlogPosts_query_graphql.node, fr);
  var data = RescriptRelay_Internal.internal_useConvertedValue(AppDiscoverBlogPosts_query_graphql.Internal.convertFragment, p.data);
  return {
          data: data,
          loadNext: React.useMemo((function () {
                  return function (param, param$1, param$2) {
                    return p.loadNext(param, {
                                onComplete: RescriptRelay_Internal.internal_nullableToOptionalExnHandler(param$1)
                              });
                  };
                }), [p.loadNext]),
          loadPrevious: React.useMemo((function () {
                  return function (param, param$1, param$2) {
                    return p.loadPrevious(param, {
                                onComplete: RescriptRelay_Internal.internal_nullableToOptionalExnHandler(param$1)
                              });
                  };
                }), [p.loadPrevious]),
          hasNext: p.hasNext,
          hasPrevious: p.hasPrevious,
          isLoadingNext: p.isLoadingNext,
          isLoadingPrevious: p.isLoadingPrevious,
          refetch: React.useMemo((function () {
                  return function (param, param$1, param$2, param$3) {
                    return p.refetch(RescriptRelay_Internal.internal_cleanObjectFromUndefinedRaw(AppDiscoverBlogPostsPaginationQuery_graphql.Internal.convertVariables(param)), internal_makeRefetchableFnOpts(param$1, param$2, undefined));
                  };
                }), [p.refetch])
        };
}

var match$1 = Ui__Styling.describe({
      blogCardsContainer: {
        TAG: /* Static */0,
        _0: {
          justifyContent: "space-between"
        }
      },
      blogCardsWideContainer: {
        TAG: /* Dynamic */1,
        _0: (function (props) {
            return {
                    paddingHorizontal: props.theme.sectionSpacing._3
                  };
          })
      },
      blogCard: {
        TAG: /* Dual */2,
        _0: {
          flex: 1
        },
        _1: (function (props) {
            var tmp = {};
            var tmp$1 = Belt_Option.map(props.cardMaxWidth, (function (prim) {
                    return prim;
                  }));
            if (tmp$1 !== undefined) {
              tmp.maxWidth = Caml_option.valFromOption(tmp$1);
            }
            return tmp;
          })
      }
    });

var stylesheet$1 = match$1[1];

var styles$1 = match$1[0];

function App__DiscoverBlogPosts$List(Props) {
  var blogPosts = Props.blogPosts;
  var layoutSize = Props.layoutSize;
  var numberOfBlogCards = Props.numberOfBlogCards;
  var cardMaxWidth = Props.cardMaxWidth;
  var match = usePagination(blogPosts);
  var match$1 = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match$1.theme;
  var styled = Curry._1(stylesheet$1, {
        theme: theme,
        cardMaxWidth: cardMaxWidth
      });
  var blogs = Belt_Option.map(match.data.blogPosts, (function (param) {
          return param.edges;
        }));
  if (blogs === undefined) {
    return null;
  }
  if (blogs.length === 0) {
    return React.createElement(App__DiscoverBlogPosts$EmptyState, {});
  }
  var blogs$1 = Belt_Array.slice(blogs, 0, numberOfBlogCards);
  return React.createElement(Ui__Group.make, {
              spacing: theme.spacing._24,
              children: Belt_Array.map(blogs$1, (function (param) {
                      var match = param.node;
                      return React.createElement(App__BlogPost__Card.make, {
                                  blogPost: match.fragmentRefs,
                                  style: Curry._1(styled, styles$1.blogCard),
                                  imageSizes: "" + String(100 / (
                                        numberOfBlogCards > 0 ? numberOfBlogCards : 1
                                      )) + "vw",
                                  key: match.id
                                });
                    })),
              containerStyle: [
                Caml_option.some(Curry._1(styled, styles$1.blogCardsContainer)),
                Bool$DecidrIoUtils.thenSome(layoutSize === "large", Curry._1(styled, styles$1.blogCardsWideContainer))
              ],
              orientation: "horizontal",
              testID: "discover-blog-posts"
            });
}

function App__DiscoverBlogPosts(Props) {
  var blogPosts = Props.blogPosts;
  var layoutSize = Props.layoutSize;
  var numberOfBlogCards = Props.numberOfBlogCards;
  var cardMaxWidth = Props.cardMaxWidth;
  var tmp = {
    blogPosts: blogPosts,
    layoutSize: layoutSize,
    numberOfBlogCards: numberOfBlogCards
  };
  if (cardMaxWidth !== undefined) {
    tmp.cardMaxWidth = cardMaxWidth;
  }
  return React.createElement(Ui__PageSection.make, {
              children: React.createElement(Ui__MarkotErrorBoundary.make, {
                    debugContext: "DiscoverBlogPosts error boundary",
                    children: React.createElement(React.Suspense, {
                          children: React.createElement(App__DiscoverBlogPosts$List, tmp),
                          fallback: React.createElement(Ui__ListsLoadingView.make, {
                                testID: "blog-posts-loading-indicator"
                              })
                        })
                  }),
              testID: "discover-blog-posts-container"
            });
}

var make = App__DiscoverBlogPosts;

export {
  make ,
}
/* match Not a pure module */
