// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Group from "../../group/components/Ui__Group.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__AppLink from "../../routing/Ui__AppLink.bs.js";
import * as Ui__Styling from "../../styling/Ui__Styling.bs.js";
import * as ReactNative from "react-native";
import * as Ui__MarkotText from "../../text/components/Ui__MarkotText.bs.js";
import * as Ui__Responsive from "../../responsive/Ui__Responsive.bs.js";
import * as Ui__MarkotImage from "../../image/components/Ui__MarkotImage.bs.js";
import * as Box$DecidrIoUniversalComponents from "@decidr-io/universal-components/src/layout/box/components/Box.bs.js";

var match = Ui__Styling.describe({
      link: {
        TAG: /* Static */0,
        _0: {
          flex: 1
        }
      },
      container: {
        TAG: /* Dual */2,
        _0: {
          alignItems: "center",
          flex: 1
        },
        _1: (function (param) {
            return {
                    backgroundColor: param.backgroundColor,
                    borderRadius: param.theme.roundness._3
                  };
          })
      },
      content: {
        TAG: /* Static */0,
        _0: {
          alignItems: "center",
          justifyContent: "center"
        }
      },
      imageContainer: {
        TAG: /* Dynamic */1,
        _0: (function (param) {
            return {
                    height: param.cardHeight,
                    width: param.cardImageSize
                  };
          })
      },
      image: {
        TAG: /* Static */0,
        _0: {
          bottom: 0,
          position: "absolute",
          zIndex: 1
        }
      },
      text: {
        TAG: /* Static */0,
        _0: {
          textAlign: "center",
          width: 160
        }
      }
    });

var stylesheet = match[1];

var styles = match[0];

function Ui__PromoCard(Props) {
  var title = Props.title;
  var image = Props.image;
  var route = Props.route;
  var a11yLabel = Props.a11yLabel;
  var alt = Props.alt;
  var backgroundColor = Props.backgroundColor;
  var containerStyle = Props.containerStyle;
  var testIDOpt = Props.testID;
  var testID = testIDOpt !== undefined ? testIDOpt : "promo-card";
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var match$1 = Curry.app(Ui__Responsive.useMedia(undefined)(undefined).mediaSelect, [
        undefined,
        {
          cardHeight: 75,
          cardImageSize: 100
        },
        undefined,
        undefined,
        undefined,
        {
          cardHeight: 110,
          cardImageSize: 170
        },
        undefined,
        {
          cardHeight: 75,
          cardImageSize: 100
        },
        undefined
      ]);
  var cardImageSize = match$1.cardImageSize;
  var styled = Curry._1(stylesheet, {
        theme: theme,
        cardHeight: match$1.cardHeight,
        cardImageSize: cardImageSize,
        backgroundColor: Belt_Option.getWithDefault(backgroundColor, theme.colors.secondaryVariant2)
      });
  var match$2 = Curry._1(Ui__AppLink.useRouting, undefined);
  var routeConfiguration = Curry._3(Ui__AppLink.makeConfiguration, route, undefined, undefined);
  var tmp = {
    children: React.createElement(Ui__Group.make, {
          spacing: theme.spacing._8,
          children: null,
          containerStyle: Curry._1(styled, styles.content),
          orientation: "horizontal"
        }, React.createElement(ReactNative.View, {
              style: Curry._1(styled, styles.imageContainer),
              children: React.createElement(Ui__MarkotImage.make, {
                    image: image,
                    alt: alt,
                    size: {
                      NAME: "fixedHeight",
                      VAL: {
                        height: cardImageSize,
                        aspectRatio: 1
                      }
                    },
                    resizeMode: "contain",
                    style: Curry._1(styled, styles.image),
                    testID: "promo-card-image"
                  })
            }), React.createElement(Ui__MarkotText.make, {
              fontFamily: theme.fontFamily.libre,
              size: "title1",
              children: title,
              style: Curry._1(styled, styles.text)
            })),
    style: [
      Caml_option.some(Curry._1(styled, styles.container)),
      containerStyle
    ],
    testID: testID
  };
  var tmp$1 = Belt_Option.map(match$2.nativeNavigationHandler, (function (navHandler) {
          return {
                  NAME: "Pressable",
                  VAL: Box$DecidrIoUniversalComponents.makePressableProps(undefined, undefined, undefined, (function (param) {
                          Curry._1(navHandler, routeConfiguration);
                        }), undefined, undefined, undefined)
                };
        }));
  if (tmp$1 !== undefined) {
    tmp.kind = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(Ui__AppLink.make, {
              configuration: routeConfiguration,
              children: React.createElement(Box$DecidrIoUniversalComponents.make, tmp),
              a11yLabel: a11yLabel,
              style: Curry._1(styled, styles.link)
            });
}

var make = Ui__PromoCard;

export {
  make ,
}
/* match Not a pure module */
