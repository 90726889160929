/* TypeScript file generated from App__DiscoverPage.resi by genType. */
/* eslint-disable import/first */


import * as React from 'react';

// @ts-ignore: Implicit any on import
import * as App__DiscoverPageBS__Es6Import from './App__DiscoverPage.bs';
const App__DiscoverPageBS: any = App__DiscoverPageBS__Es6Import;

import type {status as FeatureFlags__AppSpecificConfig_status} from '../../../../packages/feature-flags/src/local/FeatureFlags__AppSpecificConfig.gen';

// tslint:disable-next-line:interface-over-type-literal
export type Props = {
  readonly initImplementOverstockedProducts?: FeatureFlags__AppSpecificConfig_status; 
  readonly isWeb: boolean; 
  readonly webVideoStyleElement?: JSX.Element; 
  readonly initRedesignDiscoverHeroBanner?: FeatureFlags__AppSpecificConfig_status; 
  readonly videoHeight?: number; 
  readonly initOpsHeroSection?: FeatureFlags__AppSpecificConfig_status
};

export const make: React.ComponentType<{
  readonly initImplementOverstockedProducts?: FeatureFlags__AppSpecificConfig_status; 
  readonly isWeb: boolean; 
  readonly webVideoStyleElement?: JSX.Element; 
  readonly initRedesignDiscoverHeroBanner?: FeatureFlags__AppSpecificConfig_status; 
  readonly videoHeight?: number; 
  readonly initOpsHeroSection?: FeatureFlags__AppSpecificConfig_status
}> = App__DiscoverPageBS.make;
