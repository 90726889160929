// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Group from "../../../group/components/Ui__Group.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../../../styling/Ui__Styling.bs.js";
import * as ReactNative from "react-native";
import * as Ui__MarkotIcon from "../../../icons/components/Ui__MarkotIcon.bs.js";
import * as Style$ReactNative from "rescript-react-native/src/apis/Style.bs.js";
import * as Bool$DecidrIoUtils from "@decidr-io/utils/src/bool/Bool.bs.js";
import * as Ui__PagerIndicator from "../common/Ui__PagerIndicator.bs.js";
import * as Animated$ReactNative from "rescript-react-native/src/apis/Animated.bs.js";
import * as SimpleReact$DecidrIoUtils from "@decidr-io/utils/src/react/SimpleReact.bs.js";

var match = Ui__Styling.describe({
      container: {
        TAG: /* Dual */2,
        _0: {
          alignItems: "center",
          height: 24,
          justifyContent: "center",
          width: 24
        },
        _1: (function (props) {
            return {
                    backgroundColor: props.theme.colors.primaryShade4,
                    borderColor: props.theme.colors.info,
                    borderRadius: props.theme.roundness.circle,
                    borderWidth: props.theme.lineThickness.xxs,
                    opacity: props.disabled ? 0.25 : 1
                  };
          })
      },
      border: {
        TAG: /* Dynamic */1,
        _0: (function (props) {
            return {
                    borderWidth: props.theme.lineThickness.xs
                  };
          })
      }
    });

var stylesheet = match[1];

var styles = match[0];

function Ui__WebViewPager$PagerButton(Props) {
  var onPress = Props.onPress;
  var directionOpt = Props.direction;
  var disabledOpt = Props.disabled;
  var testOnly_pressedOpt = Props.testOnly_pressed;
  var testIDOpt = Props.testID;
  var containerStyle = Props.containerStyle;
  var underlayColor = Props.underlayColor;
  var direction = directionOpt !== undefined ? directionOpt : "left";
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var testOnly_pressed = testOnly_pressedOpt !== undefined ? testOnly_pressedOpt : false;
  var testID = testIDOpt !== undefined ? testIDOpt : (
      direction === "left" ? "view-pager-button-left" : "view-pager-button-right"
    );
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var match$1 = React.useState(function () {
        return false;
      });
  var setHovered = match$1[1];
  var styled = Curry._1(stylesheet, {
        theme: theme,
        disabled: disabled
      });
  return React.createElement(ReactNative.TouchableHighlight, {
              style: [
                Caml_option.some(Curry._1(styled, styles.container)),
                Bool$DecidrIoUtils.thenSome(match$1[0] && !disabled, Curry._1(styled, styles.border)),
                containerStyle
              ],
              underlayColor: Belt_Option.getWithDefault(underlayColor, theme.colors.backgroundVariant2),
              disabled: disabled,
              onPress: (function (param) {
                  Curry._1(onPress, undefined);
                }),
              testID: testID,
              testOnly_pressed: testOnly_pressed,
              children: React.createElement(Ui__MarkotIcon.make, {
                    name: direction === "left" ? "chevron-left" : "chevron-right",
                    size: 10,
                    color: theme.colors.primaryShade1
                  }),
              onMouseEnter: (function (param) {
                  Curry._1(setHovered, (function (param) {
                          return true;
                        }));
                }),
              onMouseLeave: (function (param) {
                  Curry._1(setHovered, (function (param) {
                          return false;
                        }));
                })
            });
}

var match$1 = Ui__Styling.describe({
      content: {
        TAG: /* Static */0,
        _0: {
          flex: 1
        }
      },
      indicatorsContainer: {
        TAG: /* Static */0,
        _0: {
          alignItems: "center",
          justifyContent: "center"
        }
      },
      button: {
        TAG: /* Static */0,
        _0: {
          alignSelf: "center"
        }
      }
    });

var stylesheet$1 = match$1[1];

var styles$1 = match$1[0];

function Ui__WebViewPager$NonScrollable(Props) {
  var children = Props.children;
  var style = Props.style;
  var pagerStyle = Props.pagerStyle;
  var showIndicatorsOpt = Props.showIndicators;
  var indicatorsContainerStyle = Props.indicatorsContainerStyle;
  var indicatorStyle = Props.indicatorStyle;
  var givenButtonStyle = Props.buttonStyle;
  var givenButtonUnderlayColor = Props.buttonUnderlayColor;
  var withAutoplayOpt = Props.withAutoplay;
  var autoplayTimeoutOpt = Props.autoplayTimeout;
  var testID = Props.testID;
  var showIndicators = showIndicatorsOpt !== undefined ? showIndicatorsOpt : false;
  var withAutoplay = withAutoplayOpt !== undefined ? withAutoplayOpt : false;
  var autoplayTimeout = autoplayTimeoutOpt !== undefined ? autoplayTimeoutOpt : 5000;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet$1, theme);
  var match$1 = React.useState(function () {
        return 0;
      });
  var setActiveElementIndex = match$1[1];
  var activeElementIndex = match$1[0];
  var elements = React.Children.toArray(children);
  var handleOnPress = function (counter) {
    if (counter === -1) {
      Curry._1(setActiveElementIndex, (function (param) {
              return elements.length - 1 | 0;
            }));
    } else if (counter === elements.length) {
      Curry._1(setActiveElementIndex, (function (param) {
              return 0;
            }));
    } else {
      Curry._1(setActiveElementIndex, (function (param) {
              return counter;
            }));
    }
  };
  React.useEffect((function () {
          var intervalId = withAutoplay ? Caml_option.some(setInterval((function (param) {
                        handleOnPress(activeElementIndex + 1 | 0);
                      }), autoplayTimeout)) : undefined;
          return Belt_Option.map(intervalId, (function (intervalId, param) {
                        clearInterval(intervalId);
                      }));
        }), [
        withAutoplay,
        autoplayTimeout,
        handleOnPress,
        activeElementIndex
      ]);
  var buttonStyle = Belt_Option.map(givenButtonStyle, (function (givenButtonStyle) {
          return Curry._1(givenButtonStyle, activeElementIndex);
        }));
  var buttonUnderlayColor = Belt_Option.map(givenButtonUnderlayColor, (function (givenButtonUnderlayColor) {
          return Curry._1(givenButtonUnderlayColor, activeElementIndex);
        }));
  var tmp = {
    spacing: theme.spacing._8,
    children: null
  };
  var tmp$1 = Belt_Option.map(style, (function (style) {
          return Curry._1(style, activeElementIndex);
        }));
  if (tmp$1 !== undefined) {
    tmp.containerStyle = Caml_option.valFromOption(tmp$1);
  }
  if (testID !== undefined) {
    tmp.testID = testID;
  }
  var tmp$2 = {
    onPress: (function (param) {
        handleOnPress(activeElementIndex - 1 | 0);
      }),
    direction: "left",
    containerStyle: [
      Caml_option.some(Curry._1(styled, styles$1.button)),
      buttonStyle
    ]
  };
  if (buttonUnderlayColor !== undefined) {
    tmp$2.underlayColor = Caml_option.valFromOption(buttonUnderlayColor);
  }
  var tmp$3 = {
    onPress: (function (param) {
        handleOnPress(activeElementIndex + 1 | 0);
      }),
    direction: "right",
    containerStyle: [
      Caml_option.some(Curry._1(styled, styles$1.button)),
      buttonStyle
    ]
  };
  if (buttonUnderlayColor !== undefined) {
    tmp$3.underlayColor = Caml_option.valFromOption(buttonUnderlayColor);
  }
  return React.createElement(Ui__Group.make, tmp, React.createElement(ReactNative.View, {
                  style: [
                    Caml_option.some(Curry._1(styled, styles$1.content)),
                    Belt_Option.map(pagerStyle, (function (pagerStyle) {
                            return Curry._1(pagerStyle, activeElementIndex);
                          }))
                  ],
                  children: Belt_Option.getWithDefault(Belt_Array.get(elements, activeElementIndex), null)
                }), SimpleReact$DecidrIoUtils.whenTrue(showIndicators, React.createElement(Ui__Group.make, {
                      spacing: theme.spacing._20,
                      children: null,
                      containerStyle: [
                        Caml_option.some(Curry._1(styled, styles$1.indicatorsContainer)),
                        indicatorsContainerStyle
                      ],
                      orientation: "horizontal"
                    }, React.createElement(Ui__WebViewPager$PagerButton, tmp$2), React.createElement(Ui__Group.make, {
                          spacing: theme.spacing._4,
                          children: Belt_Array.mapWithIndex(elements, (function (index, _item) {
                                  var tmp = {
                                    active: index === activeElementIndex
                                  };
                                  var tmp$1 = Belt_Option.map(indicatorStyle, (function (indicatorStyle) {
                                          return Curry._2(indicatorStyle, activeElementIndex, index === activeElementIndex);
                                        }));
                                  if (tmp$1 !== undefined) {
                                    tmp.style = Caml_option.valFromOption(tmp$1);
                                  }
                                  return React.createElement(Ui__PagerIndicator.make, tmp);
                                })),
                          containerStyle: [
                            Caml_option.some(Curry._1(styled, styles$1.indicatorsContainer)),
                            indicatorsContainerStyle
                          ],
                          orientation: "horizontal"
                        }), React.createElement(Ui__WebViewPager$PagerButton, tmp$3))));
}

function nextIndex(current, length) {
  if (current < (length - 1 | 0)) {
    return current + 1 | 0;
  } else {
    return 0;
  }
}

var match$2 = Ui__Styling.describe({
      elementContainer: {
        TAG: /* Static */0,
        _0: {
          flex: 1
        }
      },
      indicatorsContainer: {
        TAG: /* Static */0,
        _0: {
          alignItems: "center",
          justifyContent: "center"
        }
      },
      button: {
        TAG: /* Static */0,
        _0: {
          alignSelf: "center"
        }
      }
    });

var stylesheet$2 = match$2[1];

var styles$2 = match$2[0];

function Ui__WebViewPager$Scrollable(Props) {
  var children = Props.children;
  var widthOpt = Props.width;
  var height = Props.height;
  var style = Props.style;
  var showIndicatorsOpt = Props.showIndicators;
  var indicatorsContainerStyle = Props.indicatorsContainerStyle;
  var withAutoplayOpt = Props.withAutoplay;
  var autoplayTimeoutOpt = Props.autoplayTimeout;
  var indicatorStyle = Props.indicatorStyle;
  var testID = Props.testID;
  var width = widthOpt !== undefined ? widthOpt : 300;
  var showIndicators = showIndicatorsOpt !== undefined ? showIndicatorsOpt : false;
  var withAutoplay = withAutoplayOpt !== undefined ? withAutoplayOpt : false;
  var autoplayTimeout = autoplayTimeoutOpt !== undefined ? autoplayTimeoutOpt : 5000;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet$2, theme);
  var listRef = React.useRef(null);
  var indicatorsRef = React.useRef(null);
  var match$1 = React.useState(function () {
        return 0;
      });
  var setActiveIndex = match$1[1];
  var activeIndex = match$1[0];
  var scrollToActiveIndex = function (index) {
    Curry._1(setActiveIndex, (function (param) {
            return index;
          }));
    Belt_Option.forEach(Caml_option.nullable_to_opt(listRef.current), (function (listRef) {
            listRef.scrollToOffset({
                  animated: false,
                  offset: index * width
                });
          }));
    Belt_Option.forEach(Caml_option.nullable_to_opt(indicatorsRef.current), (function (indicatorsRef) {
            indicatorsRef.scrollToOffset({
                  animated: true,
                  offset: 0
                });
          }));
  };
  React.useEffect((function () {
          var intervalId = withAutoplay ? Caml_option.some(setInterval((function (param) {
                        scrollToActiveIndex(nextIndex(activeIndex, React.Children.toArray(children).length));
                      }), autoplayTimeout)) : undefined;
          return Belt_Option.map(intervalId, (function (intervalId, param) {
                        clearInterval(intervalId);
                      }));
        }), [
        withAutoplay,
        autoplayTimeout,
        scrollToActiveIndex,
        activeIndex
      ]);
  var tmp = {
    children: null
  };
  var tmp$1 = Belt_Option.map(style, (function (style) {
          return Curry._1(style, activeIndex);
        }));
  if (tmp$1 !== undefined) {
    tmp.style = Caml_option.valFromOption(tmp$1);
  }
  if (testID !== undefined) {
    tmp.testID = testID;
  }
  var tmp$2 = {
    width: width
  };
  var tmp$3 = Belt_Option.map(height, (function (prim) {
          return prim;
        }));
  if (tmp$3 !== undefined) {
    tmp$2.height = Caml_option.valFromOption(tmp$3);
  }
  var tmp$4 = Belt_Option.map(height, (function (prim) {
          return prim;
        }));
  if (tmp$4 !== undefined) {
    tmp$2.maxHeight = Caml_option.valFromOption(tmp$4);
  }
  var tmp$5 = {
    ref: indicatorsRef,
    data: React.Children.toArray(children),
    keyExtractor: (function (param, index) {
        return String(index);
      }),
    renderItem: (function (param) {
        var index = param.index;
        var tmp = {
          active: index === activeIndex
        };
        var tmp$1 = Belt_Option.map(indicatorStyle, (function (indicatorStyle) {
                return Curry._2(indicatorStyle, activeIndex, index === activeIndex);
              }));
        if (tmp$1 !== undefined) {
          tmp.style = Caml_option.valFromOption(tmp$1);
        }
        return React.createElement(Ui__Group.make, {
                    children: React.createElement(Ui__PagerIndicator.make, tmp),
                    containerStyle: {
                      paddingHorizontal: theme.spacing._2
                    }
                  });
      }),
    contentContainerStyle: {
      justifyContent: "center",
      paddingHorizontal: theme.spacing._4,
      width: Style$ReactNative.pct(100)
    },
    horizontal: true,
    scrollEnabled: false,
    showsHorizontalScrollIndicator: false
  };
  if (indicatorsContainerStyle !== undefined) {
    tmp$5.style = Caml_option.valFromOption(indicatorsContainerStyle);
  }
  return React.createElement(ReactNative.View, tmp, React.createElement(Animated$ReactNative.FlatList.make, {
                  ref: listRef,
                  data: React.Children.toArray(children),
                  keyExtractor: (function (param, index) {
                      return String(index);
                    }),
                  renderItem: (function (param) {
                      var tmp = {
                        width: width
                      };
                      var tmp$1 = Belt_Option.map(height, (function (prim) {
                              return prim;
                            }));
                      if (tmp$1 !== undefined) {
                        tmp.height = Caml_option.valFromOption(tmp$1);
                      }
                      return React.createElement(ReactNative.View, {
                                  style: [
                                    Curry._1(styled, styles$2.elementContainer),
                                    tmp
                                  ],
                                  children: param.item
                                });
                    }),
                  bounces: false,
                  decelerationRate: "fast",
                  horizontal: true,
                  onScroll: (function ($$event) {
                      var newActiveIndex = (React.Children.toArray(children).length - 1 | 0) - ((Math.round($$event.nativeEvent.contentSize.width / ($$event.nativeEvent.contentOffset.x + width)) | 0) - 1 | 0) | 0;
                      if (newActiveIndex !== activeIndex) {
                        return Curry._1(setActiveIndex, (function (param) {
                                      return newActiveIndex;
                                    }));
                      }
                      
                    }),
                  pagingEnabled: true,
                  scrollEnabled: true,
                  showsHorizontalScrollIndicator: false,
                  snapToInterval: width,
                  style: tmp$2
                }), SimpleReact$DecidrIoUtils.whenTrue(showIndicators, React.createElement(ReactNative.FlatList, tmp$5)));
}

function Ui__WebViewPager(Props) {
  var children = Props.children;
  var style = Props.style;
  var pagerStyle = Props.pagerStyle;
  var showIndicatorsOpt = Props.showIndicators;
  var indicatorsContainerStyle = Props.indicatorsContainerStyle;
  var indicatorStyle = Props.indicatorStyle;
  var buttonStyle = Props.buttonStyle;
  var buttonUnderlayColor = Props.buttonUnderlayColor;
  var withAutoplayOpt = Props.withAutoplay;
  var autoplayTimeoutOpt = Props.autoplayTimeout;
  var scrollableOpt = Props.scrollable;
  var scrollableWidth = Props.scrollableWidth;
  var testID = Props.testID;
  var pagerMaxHeightOverride = Props.pagerMaxHeightOverride;
  var showIndicators = showIndicatorsOpt !== undefined ? showIndicatorsOpt : false;
  var withAutoplay = withAutoplayOpt !== undefined ? withAutoplayOpt : false;
  var autoplayTimeout = autoplayTimeoutOpt !== undefined ? autoplayTimeoutOpt : 5000;
  var scrollable = scrollableOpt !== undefined ? scrollableOpt : false;
  if (scrollable) {
    var tmp = {
      children: children,
      showIndicators: showIndicators,
      withAutoplay: withAutoplay,
      autoplayTimeout: autoplayTimeout
    };
    if (scrollableWidth !== undefined) {
      tmp.width = scrollableWidth;
    }
    if (style !== undefined) {
      tmp.style = Caml_option.valFromOption(style);
    }
    if (indicatorsContainerStyle !== undefined) {
      tmp.indicatorsContainerStyle = Caml_option.valFromOption(indicatorsContainerStyle);
    }
    if (indicatorStyle !== undefined) {
      tmp.indicatorStyle = Caml_option.valFromOption(indicatorStyle);
    }
    if (testID !== undefined) {
      tmp.testID = Caml_option.valFromOption(testID);
    }
    return React.createElement(Ui__WebViewPager$Scrollable, tmp);
  }
  var tmp$1 = {
    children: children,
    showIndicators: showIndicators,
    withAutoplay: withAutoplay,
    autoplayTimeout: autoplayTimeout
  };
  if (style !== undefined) {
    tmp$1.style = Caml_option.valFromOption(style);
  }
  if (pagerStyle !== undefined) {
    tmp$1.pagerStyle = Caml_option.valFromOption(pagerStyle);
  }
  if (indicatorsContainerStyle !== undefined) {
    tmp$1.indicatorsContainerStyle = Caml_option.valFromOption(indicatorsContainerStyle);
  }
  if (indicatorStyle !== undefined) {
    tmp$1.indicatorStyle = Caml_option.valFromOption(indicatorStyle);
  }
  if (buttonStyle !== undefined) {
    tmp$1.buttonStyle = Caml_option.valFromOption(buttonStyle);
  }
  if (buttonUnderlayColor !== undefined) {
    tmp$1.buttonUnderlayColor = Caml_option.valFromOption(buttonUnderlayColor);
  }
  if (testID !== undefined) {
    tmp$1.testID = Caml_option.valFromOption(testID);
  }
  if (pagerMaxHeightOverride !== undefined) {
    tmp$1.pagerMaxHeightOverride = pagerMaxHeightOverride;
  }
  return React.createElement(Ui__WebViewPager$NonScrollable, tmp$1);
}

var make = Ui__WebViewPager;

export {
  make ,
}
/* match Not a pure module */
