/* TypeScript file generated from Ui__Responsive.resi by genType. */
/* eslint-disable import/first */


// @ts-ignore: Implicit any on import
import * as Curry__Es6Import from 'rescript/lib/es6/curry.js';
const Curry: any = Curry__Es6Import;

// @ts-ignore: Implicit any on import
import * as Ui__ResponsiveBS__Es6Import from './Ui__Responsive.bs';
const Ui__ResponsiveBS: any = Ui__ResponsiveBS__Es6Import;

import type {Style_t as ReactNative_Style_t} from '../../../../rescript-shims/ReactNative.shim';

import type {breakpointConfig as Media_breakpointConfig} from '@decidr-io/responsive/src/media/Media.gen';

import type {useMediaResult as Media_useMediaResult} from '@decidr-io/responsive/src/media/Media.gen';

export const defaultBreakpoints: Media_breakpointConfig = Ui__ResponsiveBS.defaultBreakpoints;

export const useMedia: <a>(_1:{ readonly breakpoints?: Media_breakpointConfig }, _2:void) => Media_useMediaResult<(_1:{
  readonly xxs?: a; 
  readonly xs?: a; 
  readonly s?: a; 
  readonly m?: a; 
  readonly l?: a; 
  readonly xl?: a; 
  readonly xxl?: a; 
  readonly default: a
}, _2:void) => a> = function <a>(Arg1: any, Arg2: any) {
  const result = Curry._2(Ui__ResponsiveBS.useMedia, Arg1.breakpoints, Arg2);
  return result
};

export const widthLimiterStyle: (_1:{ readonly isForm?: boolean; readonly forceMaxWidth?: number }, _2:void) => ReactNative_Style_t = function (Arg1: any, Arg2: any) {
  const result = Curry._3(Ui__ResponsiveBS.widthLimiterStyle, Arg1.isForm, Arg1.forceMaxWidth, Arg2);
  return result
};
