// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Ui__ViewPagerRe from "./Ui__ViewPagerRe";

var make = Ui__ViewPagerRe.make;

export {
  make ,
}
/* make Not a pure module */
