import { defaultBreakpoints } from "./Ui__Responsive.gen";
import {
  breakpointConfig,
  useMedia as useMediaOriginal,
} from "@decidr-io/responsive/src/media/Media.gen";
export { widthLimiterStyle } from "./Ui__Responsive.gen";

export const useMedia = <T>(
  config: { readonly breakpoints?: breakpointConfig } = {
    breakpoints: defaultBreakpoints,
  }
) => useMediaOriginal<T>(config);
