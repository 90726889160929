// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ReactNativeSvg from "react-native-svg";

function Ui__HeroHeart(Props) {
  var sizeOpt = Props.size;
  var size = sizeOpt !== undefined ? sizeOpt : 200;
  return React.createElement(ReactNativeSvg.Svg, {
              viewBox: "0 0 615 527",
              width: size,
              height: 0.857 * size,
              children: React.createElement(ReactNativeSvg.Path, {
                    d: "M307.552 59.4328C313.096 54.5082 318.136 50.0156 323.176 45.523C361.564 11.31 406.335 -3.20458 456.735 1.02885C532.334 7.42218 598.525 69.1092 611.713 146.002C623.473 214.601 603.649 272.746 552.662 318.622C478.911 385.061 404.655 450.895 330.652 516.988C315.868 530.207 299.488 530.38 284.704 517.247C209.945 450.549 134.934 384.024 60.5106 316.98C15.655 276.547 -4.58879 224.709 0.871157 163.886C6.5831 101.249 37.4108 53.7307 90.7503 22.9735C155.514 -14.4361 234.389 -5.36449 290.752 44.3998C296.212 49.238 301.672 54.1626 307.552 59.4328Z",
                    fill: "#E3DBCA"
                  }),
              fill: "none"
            });
}

var make = Ui__HeroHeart;

export {
  make ,
}
/* react Not a pure module */
