// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../../styling/Ui__Styling.bs.js";
import * as ReactNative from "react-native";
import * as ReactNativeSafeAreaContext from "react-native-safe-area-context";

var match = Ui__Styling.describe({
      container: {
        TAG: /* Static */0,
        _0: {
          flex: 1
        }
      }
    });

var stylesheet = match[1];

var styles = match[0];

function Ui__PageWrapper(Props) {
  var isWebOpt = Props.isWeb;
  var style = Props.style;
  var contentContainerStyle = Props.contentContainerStyle;
  var children = Props.children;
  var testID = Props.testID;
  var scrolledToTopOpt = Props.scrolledToTop;
  var onScrolledToTopOpt = Props.onScrolledToTop;
  var refreshControl = Props.refreshControl;
  var showsVerticalScrollIndicator = Props.showsVerticalScrollIndicator;
  var scrollRef = Props.scrollRef;
  var isWeb = isWebOpt !== undefined ? isWebOpt : false;
  var scrolledToTop = scrolledToTopOpt !== undefined ? scrolledToTopOpt : true;
  var onScrolledToTop = onScrolledToTopOpt !== undefined ? onScrolledToTopOpt : (function (param) {
        
      });
  var scrollViewRef = React.useRef(null);
  var scrollToTop = function (passedRef) {
    Belt_Option.forEach(Caml_option.nullable_to_opt(passedRef.current), (function (passedRef) {
            passedRef.scrollTo({
                  x: 0,
                  y: 0,
                  animated: true
                });
          }));
  };
  React.useEffect((function () {
          if (!scrolledToTop) {
            scrollToTop(scrollViewRef);
            Curry._1(onScrolledToTop, undefined);
          }
          
        }), [scrolledToTop]);
  var styled = Curry._1(stylesheet, undefined);
  var tmp;
  if (isWeb) {
    var tmp$1 = {
      style: [
        Caml_option.some(Curry._1(styled, styles.container)),
        style
      ],
      children: children
    };
    if (testID !== undefined) {
      tmp$1.testID = testID;
    }
    tmp = React.createElement(ReactNative.View, tmp$1);
  } else {
    var tmp$2;
    if (scrollRef !== undefined) {
      var partial_arg = [
        scrollRef,
        scrollViewRef
      ];
      tmp$2 = (function (param) {
          return Belt_Array.forEach(partial_arg, (function (ref) {
                        ref.current = param;
                      }));
        });
    } else {
      tmp$2 = scrollViewRef;
    }
    var tmp$3 = {
      ref: tmp$2,
      keyboardShouldPersistTaps: "handled",
      style: [
        Caml_option.some(Curry._1(styled, styles.container)),
        style
      ],
      children: children
    };
    if (contentContainerStyle !== undefined) {
      tmp$3.contentContainerStyle = Caml_option.valFromOption(contentContainerStyle);
    }
    if (refreshControl !== undefined) {
      tmp$3.refreshControl = Caml_option.valFromOption(refreshControl);
    }
    if (showsVerticalScrollIndicator !== undefined) {
      tmp$3.showsVerticalScrollIndicator = showsVerticalScrollIndicator;
    }
    if (testID !== undefined) {
      tmp$3.testID = testID;
    }
    tmp = React.createElement(ReactNative.ScrollView, tmp$3);
  }
  return React.createElement(ReactNativeSafeAreaContext.SafeAreaProvider, {
              children: tmp
            });
}

var make = Ui__PageWrapper;

export {
  make ,
}
/* match Not a pure module */
