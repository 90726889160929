// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ReactRelay from "react-relay";
import * as App__ProductCollection from "../App__ProductCollection.bs.js";
import * as RescriptRelay_Internal from "rescript-relay/src/RescriptRelay_Internal.bs.js";
import * as SimpleReact$DecidrIoUtils from "@decidr-io/utils/src/react/SimpleReact.bs.js";
import * as AppProductCollection100_query_graphql from "../../../../relay-codegen/generated/AppProductCollection100_query_graphql.bs.js";

function use(fRef) {
  var data = ReactRelay.useFragment(AppProductCollection100_query_graphql.node, fRef);
  return RescriptRelay_Internal.internal_useConvertedValue(AppProductCollection100_query_graphql.Internal.convertFragment, data);
}

function App__ProductCollection__100(Props) {
  var productCollectionQueryRef = Props.productCollectionQueryRef;
  var testIDOpt = Props.testID;
  var testID = testIDOpt !== undefined ? testIDOpt : "recent-listings";
  var data = use(productCollectionQueryRef);
  return SimpleReact$DecidrIoUtils.renderIfSome(data.productCollectionForPlaceholder100, (function (param) {
                var tmp = {
                  productCollection: param.fragmentRefs,
                  testID: testID,
                  layout: "horizontal-list"
                };
                if (param.name !== undefined) {
                  tmp.title = param.name;
                }
                return React.createElement(App__ProductCollection.make, tmp);
              }));
}

var make = App__ProductCollection__100;

export {
  make ,
}
/* react Not a pure module */
