// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../../../styling/Ui__Styling.bs.js";
import * as ReactNative from "react-native";
import * as Bool$DecidrIoUtils from "@decidr-io/utils/src/bool/Bool.bs.js";

var match = Ui__Styling.describe({
      indicator: {
        TAG: /* Dual */2,
        _0: {
          height: 10,
          width: 10
        },
        _1: (function (theme) {
            return {
                    backgroundColor: theme.colors.background,
                    borderRadius: theme.roundness._8
                  };
          })
      },
      active: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    backgroundColor: theme.colors.onPrimaryShade4
                  };
          })
      }
    });

var stylesheet = match[1];

var styles = match[0];

function Ui__PagerIndicator(Props) {
  var activeOpt = Props.active;
  var style = Props.style;
  var active = activeOpt !== undefined ? activeOpt : false;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var styled = Curry._1(stylesheet, match.theme);
  return React.createElement(ReactNative.View, {
              style: [
                Caml_option.some(Curry._1(styled, styles.indicator)),
                Bool$DecidrIoUtils.thenSome(active, Curry._1(styled, styles.active)),
                style
              ]
            });
}

var make = Ui__PagerIndicator;

export {
  make ,
}
/* match Not a pure module */
