// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ReactNativeSvg from "react-native-svg";

function Ui__SquigglyCircle(Props) {
  var sizeOpt = Props.size;
  var size = sizeOpt !== undefined ? sizeOpt : 200;
  return React.createElement(ReactNativeSvg.Svg, {
              viewBox: "0 0 557 557",
              width: size,
              height: size,
              children: React.createElement(ReactNativeSvg.Path, {
                    d: "M200.573 518.034C209.701 516.827 218.921 519.303 226.193 524.904L259.703 550.618C270.78 559.127 286.189 559.127 297.235 550.618L332.014 523.914C338.512 518.932 346.711 516.735 354.818 517.818L398.322 523.542C412.153 525.368 425.489 517.663 430.842 504.759L447.582 464.285C450.707 456.704 456.71 450.701 464.29 447.576L504.762 430.835C517.665 425.513 525.37 412.145 523.544 398.314L518.036 356.385C516.83 347.257 519.305 338.035 524.906 330.764L550.618 297.252C559.127 286.174 559.127 270.764 550.618 259.717L523.915 224.937C518.934 218.438 516.737 210.238 517.82 202.131L523.544 158.625C525.37 144.793 517.665 131.456 504.762 126.103L464.29 109.362C456.71 106.237 450.707 100.234 447.582 92.6528L430.842 52.1786C425.52 39.2751 412.153 31.5702 398.322 33.3959L354.818 39.1204C346.711 40.2344 338.512 38.0374 332.045 33.0864L297.266 6.38211C286.189 -2.12737 270.78 -2.12737 259.734 6.38211L224.955 33.0864C218.457 38.0374 210.258 40.2344 202.151 39.1823L158.647 33.4577C144.816 31.6321 131.48 39.337 126.127 52.2405L109.418 92.7147C106.262 100.265 100.259 106.268 92.7096 109.424L52.2376 126.134C39.3349 131.487 31.6303 144.824 33.4559 158.655L39.1801 202.162C40.2322 210.269 38.0353 218.469 33.0846 224.937L6.38176 259.717C-2.12725 270.795 -2.12725 286.205 6.38176 297.252L33.0846 332.032C38.0662 338.531 40.2631 346.731 39.1801 354.838L33.4559 398.344C31.6303 412.176 39.3349 425.513 52.2376 430.866L92.7096 447.607C100.29 450.732 106.293 456.735 109.418 464.316L126.158 504.79C131.48 517.694 144.847 525.399 158.678 523.573L200.573 518.034Z",
                    fill: "#E5F9D4"
                  }),
              fill: "none"
            });
}

var make = Ui__SquigglyCircle;

export {
  make ,
}
/* react Not a pure module */
