import { useState } from "react";
import type { NextPage, NextPageContext } from "next";
import Head from "next/head";

import { DiscoverPage } from "@markot/app/src/discover";
import { PageWrapper } from "@markot/ui/src/pageWrapper";
import { ToastError } from "@markot/ui/src/toast/components/error";
import { useMedia } from "@markot/ui/src/responsive";

import { PageShell } from "../src/pageShell";
import { Footer } from "../src/footer";
import {
  getFeatureFlagsFromRemoteConfig,
  FeatureFlagsRemoteConfig,
  toFeatureFlagStatus,
  toExtendedFFStatus,
} from "../src/utils";
import { makePageTitle } from "../src/utils/makePageTitle";

// NOTE We use these props for Amplify social auth error handling.
// When someone tries to log in wih a social auth provider and fails, Amplify will redirect to home page
// with these query params.
type Props = {
  error?: string;
  error_description?: string;
  state?: string;
  featureFlags: FeatureFlagsRemoteConfig;
};

const Home: NextPage<Props> = ({
  error,
  state,
  error_description,
  featureFlags,
}) => {
  const [socialAuthError, setSocialAuthError] = useState<string | undefined>(
    error
  );

  const screenSize = useMedia<"large" | "small">().mediaSelect({
    xl: "large",
    default: "small",
  });

  const videoHeight = useMedia<number>().mediaSelect({
    s: 300,
    m: 400,
    l: 500,
    xl: 600,
    xxl: 800,
    default: 200,
  });

  return (
    <>
      <Head>
        <title>{makePageTitle("Discover")}</title>
        <meta
          name="description"
          content="The official Markot app is the new and improved way to shop for the most sought-after baby brands. Buy and sell pre-loved items or buy them new - it's up to you"
        />
        <link rel="icon" href="/au/favicon.ico" />
      </Head>
      <PageShell
        isDiscoverPage={true}
        showPromoBanner={toExtendedFFStatus(featureFlags["promoBanner230227"])}
      >
        <PageWrapper>
          <DiscoverPage
            isWeb={true}
            // "expo-av": "12.0.4"
            // Currently it is not possible to style video on the web via props
            // So that's why we are adding styles directly to the video.
            // This will be fixed with expo-av 13 version and new Expo SDK 47.
            // We couldn't fix it with patch package either.
            webVideoStyleElement={
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              <style jsx="true" global="true">{`
                video {
                  height: ${videoHeight}px;
                  width: 100%;
                }
              `}</style>
            }
            videoHeight={videoHeight}
            initOpsHeroSection={toFeatureFlagStatus(
              featureFlags["heroSection230227"]
            )}
            initRedesignDiscoverHeroBanner={toFeatureFlagStatus(
              featureFlags["redesignDiscoverHeroBanner"]
            )}
            initImplementOverstockedProducts={toFeatureFlagStatus(
              featureFlags["implementOverstockedProducts"]
            )}
          />
          <Footer />
        </PageWrapper>
      </PageShell>
      {socialAuthError != undefined &&
        state != undefined &&
        error_description != undefined && (
          <ToastError
            title="An error has occurred"
            description="Could not sign in with this provider"
            onDismiss={() => setSocialAuthError(undefined)}
            wrapperStyle={
              screenSize === "small"
                ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  { position: "fixed" as any, bottom: 70 }
                : undefined
            }
          />
        )}
    </>
  );
};

export default Home;

export const getServerSideProps = async (context: NextPageContext) => {
  const { error, error_description, state } = context.query;

  const featureFlags = await getFeatureFlagsFromRemoteConfig();

  return {
    props: {
      error: error ?? null,
      error_description: error_description ?? null,
      state: state ?? null,
      featureFlags,
    }, // will be passed to the page component as props
  };
};
