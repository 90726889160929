// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Group from "../../../../../ui/src/group/components/Ui__Group.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Heading from "../../../../../ui/src/text/components/Ui__Heading.bs.js";
import * as Ui__Styling from "../../../../../ui/src/styling/Ui__Styling.bs.js";
import * as ReactRelay from "react-relay";
import * as ReactNative from "react-native";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";
import * as Ui__Responsive from "../../../../../ui/src/responsive/Ui__Responsive.bs.js";
import * as Ui__PageSection from "../../../../../ui/src/pageSection/components/Ui__PageSection.bs.js";
import * as Ui__CarouselButton from "../../../../../ui/src/carouselButton/components/Ui__CarouselButton.bs.js";
import * as Ui__ListEmptyState from "../../../../../ui/src/listEmptyState/components/Ui__ListEmptyState.bs.js";
import * as Ui__ListsLoadingView from "../../../../../ui/src/loading/components/Ui__ListsLoadingView.bs.js";
import * as RescriptRelay_Internal from "rescript-relay/src/RescriptRelay_Internal.bs.js";
import * as Ui__MarkotErrorBoundary from "../../../../../ui/src/error/markotErrorBoundary/Ui__MarkotErrorBoundary.bs.js";
import * as App__Category__CardWithCoverImage from "../../cardWithCoverImage/components/App__Category__CardWithCoverImage.bs.js";
import * as AppCategoryShopByList_query_graphql from "../../../../../relay-codegen/generated/AppCategoryShopByList_query_graphql.bs.js";
import * as AppCategoryShopByListPaginationQuery_graphql from "../../../../../relay-codegen/generated/AppCategoryShopByListPaginationQuery_graphql.bs.js";

var widthLimiterStyle = Ui__Responsive.widthLimiterStyle(undefined, undefined, undefined);

var getConnectionNodes = AppCategoryShopByList_query_graphql.Utils.getConnectionNodes;

function internal_makeRefetchableFnOpts(fetchPolicy, onComplete, param) {
  var tmp = {};
  var tmp$1 = RescriptRelay.mapFetchPolicy(fetchPolicy);
  if (tmp$1 !== undefined) {
    tmp.fetchPolicy = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = RescriptRelay_Internal.internal_nullableToOptionalExnHandler(onComplete);
  if (tmp$2 !== undefined) {
    tmp.onComplete = Caml_option.valFromOption(tmp$2);
  }
  return tmp;
}

function usePagination(fr) {
  var p = ReactRelay.usePaginationFragment(AppCategoryShopByList_query_graphql.node, fr);
  var data = RescriptRelay_Internal.internal_useConvertedValue(AppCategoryShopByList_query_graphql.Internal.convertFragment, p.data);
  return {
          data: data,
          loadNext: React.useMemo((function () {
                  return function (param, param$1, param$2) {
                    return p.loadNext(param, {
                                onComplete: RescriptRelay_Internal.internal_nullableToOptionalExnHandler(param$1)
                              });
                  };
                }), [p.loadNext]),
          loadPrevious: React.useMemo((function () {
                  return function (param, param$1, param$2) {
                    return p.loadPrevious(param, {
                                onComplete: RescriptRelay_Internal.internal_nullableToOptionalExnHandler(param$1)
                              });
                  };
                }), [p.loadPrevious]),
          hasNext: p.hasNext,
          hasPrevious: p.hasPrevious,
          isLoadingNext: p.isLoadingNext,
          isLoadingPrevious: p.isLoadingPrevious,
          refetch: React.useMemo((function () {
                  return function (param, param$1, param$2, param$3) {
                    return p.refetch(RescriptRelay_Internal.internal_cleanObjectFromUndefinedRaw(AppCategoryShopByListPaginationQuery_graphql.Internal.convertVariables(param)), internal_makeRefetchableFnOpts(param$1, param$2, undefined));
                  };
                }), [p.refetch])
        };
}

var match = Ui__Styling.describe({
      scrollViewContent: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    paddingHorizontal: theme.sectionSpacing._05,
                    paddingVertical: theme.sectionSpacing._05
                  };
          })
      },
      largeScreenContainer: {
        TAG: /* Dual */2,
        _0: {
          overflow: "hidden"
        },
        _1: (function (theme) {
            return {
                    paddingVertical: theme.sectionSpacing._05
                  };
          })
      },
      buttonsContainer: {
        TAG: /* Dual */2,
        _0: {
          justifyContent: "space-between",
          position: "absolute",
          width: Ui__Styling.pct(100)
        },
        _1: (function (theme) {
            return {
                    paddingHorizontal: theme.spacing._10
                  };
          })
      }
    });

var stylesheet = match[1];

var styles = match[0];

function App__Category__ShopByList$List(Props) {
  var categories = Props.categories;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet, theme);
  var match$1 = usePagination(categories);
  var match$2 = App__Category__CardWithCoverImage.useCardSize(undefined);
  var match$3 = Ui__CarouselButton.useSizes("s");
  var screenSize = Curry.app(Ui__Responsive.useMedia(undefined)(undefined).mediaSelect, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        "large",
        undefined,
        "small",
        undefined
      ]);
  var match$4 = React.useState(function () {
        return 1;
      });
  var setElementIndex = match$4[1];
  var elementIndex = match$4[0];
  var maybeNodes = match$1.data.categories;
  var categories$1 = Belt_Option.map(maybeNodes, getConnectionNodes);
  if (categories$1 !== undefined) {
    if (categories$1.length !== 0) {
      if (screenSize === "small") {
        return React.createElement(ReactNative.ScrollView, {
                    contentContainerStyle: Curry._1(styled, styles.scrollViewContent),
                    horizontal: true,
                    showsHorizontalScrollIndicator: false,
                    children: React.createElement(Ui__Group.make, {
                          children: Belt_Array.map(categories$1, (function (category) {
                                  return React.createElement(App__Category__CardWithCoverImage.make, {
                                              category: category.fragmentRefs,
                                              key: category.id
                                            });
                                })),
                          orientation: "horizontal"
                        })
                  });
      } else {
        return React.createElement(Ui__Group.make, {
                    children: null,
                    containerStyle: [
                      Curry._1(styled, styles.largeScreenContainer),
                      widthLimiterStyle
                    ]
                  }, React.createElement(Ui__Group.make, {
                        spacing: theme.spacing._10,
                        children: Belt_Array.map(categories$1, (function (category) {
                                return React.createElement(App__Category__CardWithCoverImage.make, {
                                            category: category.fragmentRefs,
                                            key: category.id
                                          });
                              })),
                        containerStyle: [{
                            right: -theme.sectionSpacing._1 + (elementIndex - 1 | 0) * match$2.width
                          }],
                        orientation: "horizontal"
                      }), React.createElement(Ui__Group.make, {
                        children: null,
                        containerStyle: [
                          Curry._1(styled, styles.buttonsContainer),
                          {
                            top: match$2.height / 2 + match$3[0] / 2
                          }
                        ],
                        orientation: "horizontal"
                      }, React.createElement(Ui__CarouselButton.make, {
                            onPress: (function (param) {
                                Curry._1(setElementIndex, (function (prev) {
                                        return prev - 1 | 0;
                                      }));
                              }),
                            direction: "left",
                            disabled: elementIndex === 1,
                            disabledOpacity: 0
                          }), React.createElement(Ui__CarouselButton.make, {
                            onPress: (function (param) {
                                Curry._1(setElementIndex, (function (prev) {
                                        return prev + 1 | 0;
                                      }));
                              }),
                            direction: "right",
                            disabled: elementIndex === (categories$1.length - 1 | 0),
                            disabledOpacity: 0
                          })));
      }
    } else {
      return React.createElement(Ui__ListEmptyState.make, {
                  description: "No categories to show! Try refreshing the page."
                });
    }
  } else {
    return null;
  }
}

var match$1 = Ui__Styling.describe({
      heading: {
        TAG: /* Dynamic */1,
        _0: (function (props) {
            return {
                    paddingHorizontal: props.paddingHorizontal,
                    paddingTop: props.paddingTop
                  };
          })
      }
    });

var stylesheet$1 = match$1[1];

var styles$1 = match$1[0];

function App__Category__ShopByList(Props) {
  var categories = Props.categories;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var match$1 = Curry.app(Ui__Responsive.useMedia(undefined)(undefined).mediaSelect, [
        undefined,
        undefined,
        undefined,
        {
          paddingTop: theme.spacing._12,
          paddingHorizontal: theme.spacing._28
        },
        undefined,
        {
          paddingTop: theme.spacing._14,
          paddingHorizontal: theme.spacing._38
        },
        undefined,
        {
          paddingTop: theme.spacing._12,
          paddingHorizontal: theme.spacing._12
        },
        undefined
      ]);
  var styled = Curry._1(stylesheet$1, {
        paddingTop: match$1.paddingTop,
        paddingHorizontal: match$1.paddingHorizontal
      });
  return React.createElement(Ui__PageSection.make, {
              children: null,
              testID: "shop-by-category-container"
            }, React.createElement(ReactNative.View, {
                  style: [
                    Curry._1(styled, styles$1.heading),
                    widthLimiterStyle
                  ],
                  children: React.createElement(Ui__Heading.make, {
                        fontFamily: theme.fontFamily.libre,
                        size: "title3",
                        level: 2,
                        children: "Shop by category",
                        ellipsizeMode: "tail",
                        numberOfLines: 1
                      })
                }), React.createElement(Ui__MarkotErrorBoundary.make, {
                  debugContext: "ShopByList error boundary",
                  children: React.createElement(React.Suspense, {
                        children: React.createElement(App__Category__ShopByList$List, {
                              categories: categories
                            }),
                        fallback: React.createElement(Ui__ListsLoadingView.make, {
                              testID: "shop-by-category-loading-indicator"
                            })
                      })
                }));
}

var make = App__Category__ShopByList;

export {
  make ,
}
/* widthLimiterStyle Not a pure module */
