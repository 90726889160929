// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Group from "../../../ui/src/group/components/Ui__Group.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../../../ui/src/styling/Ui__Styling.bs.js";
import * as Utils__A11y from "../../../utils/src/Utils__A11y.bs.js";
import * as ReactRelay from "react-relay";
import * as Ui__Postcard from "../../../ui/src/banners/components/postcard/Ui__Postcard.bs.js";
import * as ReactNative from "react-native";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";
import * as Ui__PromoCard from "../../../ui/src/promoCard/components/Ui__PromoCard.bs.js";
import * as Ui__ViewPager from "../../../ui/src/viewPager/components/Ui__ViewPager.bs.js";
import * as Ui__Responsive from "../../../ui/src/responsive/Ui__Responsive.bs.js";
import * as Ui__PageWrapper from "../../../ui/src/pageWrapper/components/Ui__PageWrapper.bs.js";
import * as Ui__DiscoverHero from "../../../ui/src/discoverHero/Ui__DiscoverHero.bs.js";
import * as Bool$DecidrIoUtils from "@decidr-io/utils/src/bool/Bool.bs.js";
import * as Ui__DiscoverHeader from "../../../ui/src/discoverHeader/components/Ui__DiscoverHeader.bs.js";
import * as Ui__MarkotFeatures from "../../../ui/src/markotFeatures/components/Ui__MarkotFeatures.bs.js";
import * as Ui__OverstockedTag from "../../../ui/src/overstockedTag/Ui__OverstockedTag.bs.js";
import * as App__DiscoverBlogPosts from "../blogPosts/App__DiscoverBlogPosts.bs.js";
import * as App__ProductCollection from "../productCollection/App__ProductCollection.bs.js";
import * as RescriptRelay_Internal from "rescript-relay/src/RescriptRelay_Internal.bs.js";
import * as Ui__NewPromoCarouselCard from "../../../ui/src/promoCarouselCard/components/Ui__NewPromoCarouselCard.bs.js";
import * as App__Category__ShopByList from "../category/shopByList/components/App__Category__ShopByList.bs.js";
import * as SimpleReact$DecidrIoUtils from "@decidr-io/utils/src/react/SimpleReact.bs.js";
import * as App__ProductCollection__100 from "../productCollection/_100/App__ProductCollection__100.bs.js";
import * as AppDiscoverPageQuery_graphql from "../../../relay-codegen/generated/AppDiscoverPageQuery_graphql.bs.js";
import * as FeatureFlags__LocalFeatureFlags from "../../../feature-flags/src/local/FeatureFlags__LocalFeatureFlags.bs.js";
import * as AppDiscoverPage_200Collection_query_graphql from "../../../relay-codegen/generated/AppDiscoverPage_200Collection_query_graphql.bs.js";
import * as AppDiscoverPage_300Collection_query_graphql from "../../../relay-codegen/generated/AppDiscoverPage_300Collection_query_graphql.bs.js";

function use(fRef) {
  var data = ReactRelay.useFragment(AppDiscoverPage_200Collection_query_graphql.node, fRef);
  return RescriptRelay_Internal.internal_useConvertedValue(AppDiscoverPage_200Collection_query_graphql.Internal.convertFragment, data);
}

function App__DiscoverPage$MostLikedCollection(Props) {
  var productCollectionQueryRef = Props.productCollectionQueryRef;
  var data = use(productCollectionQueryRef);
  return SimpleReact$DecidrIoUtils.renderIfSome(data.productCollectionForPlaceholder200, (function (param) {
                var tmp = {
                  productCollection: param.fragmentRefs,
                  testID: "most-liked",
                  layout: "horizontal-list"
                };
                if (param.name !== undefined) {
                  tmp.title = param.name;
                }
                return React.createElement(App__ProductCollection.make, tmp);
              }));
}

function use$1(fRef) {
  var data = ReactRelay.useFragment(AppDiscoverPage_300Collection_query_graphql.node, fRef);
  return RescriptRelay_Internal.internal_useConvertedValue(AppDiscoverPage_300Collection_query_graphql.Internal.convertFragment, data);
}

function App__DiscoverPage$MostViewedCollection(Props) {
  var productCollectionQueryRef = Props.productCollectionQueryRef;
  var data = use$1(productCollectionQueryRef);
  return SimpleReact$DecidrIoUtils.renderIfSome(data.productCollectionForPlaceholder300, (function (param) {
                var tmp = {
                  productCollection: param.fragmentRefs,
                  testID: "most-viewed",
                  layout: "horizontal-list"
                };
                if (param.name !== undefined) {
                  tmp.title = param.name;
                }
                return React.createElement(App__ProductCollection.make, tmp);
              }));
}

function use$2(variables, fetchPolicy, fetchKey, networkCacheConfig, param) {
  var data = ReactRelay.useLazyLoadQuery(AppDiscoverPageQuery_graphql.node, RescriptRelay_Internal.internal_cleanObjectFromUndefinedRaw(AppDiscoverPageQuery_graphql.Internal.convertVariables(variables)), {
        fetchKey: fetchKey,
        fetchPolicy: RescriptRelay.mapFetchPolicy(fetchPolicy),
        networkCacheConfig: networkCacheConfig
      });
  return RescriptRelay_Internal.internal_useConvertedValue(AppDiscoverPageQuery_graphql.Internal.convertResponse, data);
}

var match = Ui__Styling.describe({
      alignSelfCenter: {
        TAG: /* Static */0,
        _0: {
          alignSelf: "center"
        }
      },
      vimeoEmbed: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    paddingHorizontal: theme.sectionSpacing._05,
                    paddingVertical: theme.sectionSpacing._05
                  };
          })
      },
      video: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    paddingHorizontal: theme.sectionSpacing._05,
                    paddingVertical: theme.sectionSpacing._05
                  };
          })
      },
      postcardsContainer: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    paddingHorizontal: theme.sectionSpacing._05,
                    paddingVertical: theme.sectionSpacing._2
                  };
          })
      },
      postcardLargeScreen: {
        TAG: /* Static */0,
        _0: {
          flex: 1
        }
      },
      lightPostcard: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    backgroundColor: theme.colors.primary
                  };
          })
      },
      lightPostcardImage: {
        TAG: /* Static */0,
        _0: {
          opacity: 0.9
        }
      },
      overstockedTagText: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    color: theme.colors.surface,
                    fontWeight: "500"
                  };
          })
      },
      overstockedTag: {
        TAG: /* Static */0,
        _0: {
          backgroundColor: "transparent"
        }
      },
      subtitleBanner: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    color: theme.colors.surface,
                    fontWeight: "300"
                  };
          })
      },
      subtitleBannerBold: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    color: theme.colors.surface,
                    fontWeight: "600"
                  };
          })
      }
    });

var stylesheet = match[1];

var styles = match[0];

var widthLimiterStyle = Ui__Responsive.widthLimiterStyle(undefined, undefined, undefined);

function App__DiscoverPage(Props) {
  var isWeb = Props.isWeb;
  var initOpsHeroSection = Props.initOpsHeroSection;
  var initRedesignDiscoverHeroBanner = Props.initRedesignDiscoverHeroBanner;
  var initImplementOverstockedProducts = Props.initImplementOverstockedProducts;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var match$1 = Curry.app(Ui__Responsive.useMedia(undefined)(undefined).mediaSelect, [
        undefined,
        undefined,
        {
          layoutSize: "medium",
          promoCardsLayoutSize: "medium",
          cardMaxWidth: undefined,
          scrollableListsFirst: 25,
          gridListFirst: 25,
          blogPostsFirst: 25,
          postcardsLayout: "vertical",
          videoHeight: 300
        },
        {
          layoutSize: "medium",
          promoCardsLayoutSize: "medium",
          cardMaxWidth: 500,
          scrollableListsFirst: 25,
          gridListFirst: 25,
          blogPostsFirst: 25,
          postcardsLayout: "vertical",
          videoHeight: 400
        },
        {
          layoutSize: "medium",
          promoCardsLayoutSize: "large",
          cardMaxWidth: 500,
          scrollableListsFirst: 25,
          gridListFirst: 25,
          blogPostsFirst: 25,
          postcardsLayout: "horizontal",
          videoHeight: 500
        },
        {
          layoutSize: "large",
          promoCardsLayoutSize: "large",
          cardMaxWidth: 370,
          scrollableListsFirst: 25,
          gridListFirst: 25,
          blogPostsFirst: 25,
          postcardsLayout: "horizontal",
          videoHeight: 600
        },
        {
          layoutSize: "large",
          promoCardsLayoutSize: "large",
          cardMaxWidth: 370,
          scrollableListsFirst: 25,
          gridListFirst: 25,
          blogPostsFirst: 25,
          postcardsLayout: "horizontal",
          videoHeight: 800
        },
        {
          layoutSize: "small",
          promoCardsLayoutSize: "small",
          cardMaxWidth: undefined,
          scrollableListsFirst: 25,
          gridListFirst: 25,
          blogPostsFirst: 25,
          postcardsLayout: "vertical",
          videoHeight: 200
        },
        undefined
      ]);
  var postcardsLayout = match$1.postcardsLayout;
  var layoutSize = match$1.layoutSize;
  var result = use$2({
        blogPostsFirst: match$1.blogPostsFirst,
        collectionPlaceholderCode100: "discover-100",
        collectionPlaceholderCode200: "discover-200",
        collectionPlaceholderCode300: "discover-300",
        gridListFirst: match$1.gridListFirst,
        scrollableListsFirst: match$1.scrollableListsFirst
      }, undefined, undefined, undefined, undefined);
  var styled = Curry._1(stylesheet, theme);
  var numberOfBlogCards = layoutSize === "medium" ? 2 : (
      layoutSize === "small" ? 1 : 3
    );
  var carouselCardHeight = Ui__NewPromoCarouselCard.useCardHeight(undefined);
  var postcardImageSizes = postcardsLayout === "horizontal" ? "45vw" : "90vw";
  var opsHeroSection = FeatureFlags__LocalFeatureFlags.use("heroSection230227");
  var redesignDiscoverHeroBanner = FeatureFlags__LocalFeatureFlags.use("redesignDiscoverHeroBanner");
  var implementOverstockedProducts = FeatureFlags__LocalFeatureFlags.use("implementOverstockedProducts");
  var implementOverstockedProducts$1 = Belt_Option.getWithDefault(initImplementOverstockedProducts, implementOverstockedProducts);
  var overstockedTag = layoutSize === "small" ? React.createElement(ReactNative.View, {
          children: React.createElement(Ui__OverstockedTag.make, {
                text: "OVERSTOCKED",
                textStyle: Curry._1(styled, styles.overstockedTagText),
                size: "large",
                style: Curry._1(styled, styles.overstockedTag),
                symbolColor: "#282828"
              })
        }) : null;
  var match$2 = Belt_Option.getWithDefault(initRedesignDiscoverHeroBanner, redesignDiscoverHeroBanner);
  var tmp = {
    title: "Discover brands for overstocked",
    titleColor: theme.colors.onPrimary,
    buttonTitle: "SHOP OVERSTOCKED",
    buttonKind: "secondary",
    routeConfiguration: {
      route: {
        TAG: /* Search */18,
        searchTerm: "Overstocked",
        loadDataInitially: true,
        sellingNewItems: undefined,
        brand: undefined,
        category: undefined,
        productType: "Overstocked"
      },
      a11yLabel: "Browse overstocked products"
    },
    image: {
      src: "https://cdn.filestackcontent.com/zpZEZXwTpyj1GDu1xQ0f",
      width: 2000,
      height: 970
    },
    imageSizes: postcardImageSizes,
    imageAlt: "A baby laying down in bed",
    headingLevel: 2
  };
  var tmp$1 = Bool$DecidrIoUtils.thenSome(postcardsLayout === "horizontal", Curry._1(styled, styles.postcardLargeScreen));
  if (tmp$1 !== undefined) {
    tmp.style = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = {
    title: "Find the items you will love",
    titleColor: theme.colors.onPrimary,
    buttonTitle: "SHOP REFURBISHED",
    buttonKind: "secondary",
    routeConfiguration: {
      route: {
        TAG: /* Search */18,
        searchTerm: "Refurbished",
        loadDataInitially: true,
        sellingNewItems: undefined,
        brand: undefined,
        category: undefined,
        productType: "Refurbished"
      },
      a11yLabel: "Browse refurbished products"
    },
    image: {
      src: "https://cdn.filestackcontent.com/stjxzVCCTqqGC1t8aNpi",
      width: 2000,
      height: 970
    },
    imageSizes: postcardImageSizes,
    imageAlt: "A baby playing with its toy",
    headingLevel: 2
  };
  var tmp$3 = Bool$DecidrIoUtils.thenSome(postcardsLayout === "horizontal", Curry._1(styled, styles.postcardLargeScreen));
  if (tmp$3 !== undefined) {
    tmp$2.style = Caml_option.valFromOption(tmp$3);
  }
  var tmp$4 = {
    blogPosts: result.fragmentRefs,
    layoutSize: layoutSize,
    numberOfBlogCards: numberOfBlogCards
  };
  if (match$1.cardMaxWidth !== undefined) {
    tmp$4.cardMaxWidth = match$1.cardMaxWidth;
  }
  var tmp$5 = {
    spacing: theme.sectionSpacing._2,
    children: React.createElement(App__DiscoverBlogPosts.make, tmp$4),
    containerStyle: widthLimiterStyle,
    includeEndSpacing: true,
    includeStartSpacing: true
  };
  var tmp$6 = Utils__A11y.toSafeA11yRole("complementary");
  if (tmp$6 !== undefined) {
    tmp$5.accessibilityRole = Caml_option.valFromOption(tmp$6);
  }
  return React.createElement(Ui__PageWrapper.make, {
              isWeb: isWeb,
              children: null,
              testID: "discover-page-container"
            }, match$2 === "off" ? (Belt_Option.getWithDefault(initOpsHeroSection, opsHeroSection), React.createElement(Ui__DiscoverHeader.make, {})) : React.createElement(Ui__DiscoverHero.make, {
                    opsHeroSection: Belt_Option.getWithDefault(initOpsHeroSection, opsHeroSection)
                  }), React.createElement(Ui__Group.make, {
                  spacing: theme.sectionSpacing._2,
                  children: null,
                  containerStyle: widthLimiterStyle,
                  testID: "discover-page-content",
                  includeEndSpacing: true,
                  includeStartSpacing: true
                }, React.createElement(Ui__Group.make, {
                      spacing: theme.sectionSpacing._05,
                      children: null,
                      containerStyle: [
                        {
                          paddingHorizontal: theme.sectionSpacing._05
                        },
                        widthLimiterStyle
                      ],
                      orientation: match$1.promoCardsLayoutSize === "large" ? "horizontal" : "vertical",
                      accessibilityRole: "navigation"
                    }, React.createElement(Ui__PromoCard.make, {
                          title: "Buy openbox",
                          image: {
                            src: "https://cdn.filestackcontent.com/xoRpoj5WSiPnACSfwqNw",
                            width: 400,
                            height: 400
                          },
                          route: {
                            TAG: /* Search */18,
                            searchTerm: undefined,
                            loadDataInitially: true,
                            sellingNewItems: undefined,
                            brand: undefined,
                            category: undefined,
                            productType: "Open Box"
                          },
                          a11yLabel: "Browse preloved products",
                          alt: "An image of a wheel stroller",
                          testID: "buy-preloved-card"
                        }), React.createElement(Ui__PromoCard.make, {
                          title: "Buy refurbished",
                          image: {
                            src: "https://cdn.filestackcontent.com/rfUZBZZcTjeYiPsFtSHd",
                            width: 400,
                            height: 400
                          },
                          route: {
                            TAG: /* Search */18,
                            searchTerm: "Refurbished",
                            loadDataInitially: true,
                            sellingNewItems: undefined,
                            brand: undefined,
                            category: undefined,
                            productType: "Refurbished"
                          },
                          a11yLabel: "Browse refurbished products",
                          alt: "An image of a pram",
                          testID: "buy-refurbished-card"
                        }), implementOverstockedProducts$1 === "off" ? React.createElement(Ui__PromoCard.make, {
                            title: "Sell yours",
                            image: {
                              src: "https://cdn.filestackcontent.com/b4hkqFhrQBiJHxoib43X",
                              width: 400,
                              height: 400
                            },
                            route: {
                              TAG: /* CreateListingFlow */8,
                              flow: undefined
                            },
                            a11yLabel: "Go list an item",
                            alt: "An image of a baby carrier"
                          }) : React.createElement(Ui__PromoCard.make, {
                            title: "Buy overstock",
                            image: {
                              src: "https://cdn.filestackcontent.com/fnOLLLSTBYnOazSm8wZt",
                              width: 400,
                              height: 400
                            },
                            route: {
                              TAG: /* Search */18,
                              searchTerm: "Overstock",
                              loadDataInitially: true,
                              sellingNewItems: undefined,
                              brand: undefined,
                              category: undefined,
                              productType: "Overstocked"
                            },
                            a11yLabel: "Browse overstocked products",
                            alt: "An image of a pram",
                            testID: "buy-overstocked-card"
                          })), React.createElement(Ui__ViewPager.make, {
                      children: null,
                      showIndicators: true,
                      withAutoplay: true,
                      pagerMaxHeightOverride: carouselCardHeight | 0
                    }, React.createElement(Ui__NewPromoCarouselCard.make, {
                          backgroundColor: theme.colors.primary,
                          textLines: [
                            "Save big on Joolz",
                            "new in box!"
                          ],
                          foregroundColor: theme.colors.secondaryVariant2,
                          images: {
                            smallScreen: {
                              src: "https://cdn.filestackcontent.com/CH4F9nQRNiPkIXmUPSOt",
                              width: 363,
                              height: 567
                            },
                            largeScreen: {
                              src: "https://cdn.filestackcontent.com/wqXaTvW6SaCFKcnUEvdb",
                              width: 1974,
                              height: 978
                            }
                          },
                          alt: "Save big on Joolz new in box!",
                          buttonKind: "secondary",
                          buttonTitle: "SHOP NOW",
                          routeConfiguration: {
                            route: {
                              TAG: /* Search */18,
                              searchTerm: "overstocked joolz",
                              loadDataInitially: true,
                              sellingNewItems: undefined,
                              brand: "Joolz",
                              category: undefined,
                              productType: "Overstocked"
                            },
                            a11yLabel: "Browse joolz products"
                          },
                          priority: "high",
                          contentUpRight: overstockedTag,
                          spacingGroupElement: layoutSize === "small" ? 10 : theme.sectionSpacing._1
                        }), React.createElement(Ui__NewPromoCarouselCard.make, {
                          backgroundColor: theme.colors.background,
                          textLines: [
                            "Only the most coveted",
                            "baby brands."
                          ],
                          foregroundColor: theme.colors.primary,
                          images: {
                            smallScreen: {
                              src: "https://cdn.filestackcontent.com/q4ZZ0TJ6TIqHBPMpdhlH",
                              width: 363,
                              height: 567
                            },
                            largeScreen: {
                              src: "https://cdn.filestackcontent.com/zWCdOgUTTxW6zDsoSYdN",
                              width: 1974,
                              height: 978
                            }
                          },
                          alt: "A picture of a baby toy",
                          buttonKind: "secondary",
                          buttonTitle: "SHOP NOW",
                          routeConfiguration: {
                            route: {
                              TAG: /* Search */18,
                              searchTerm: undefined,
                              loadDataInitially: true,
                              sellingNewItems: true,
                              brand: undefined,
                              category: undefined,
                              productType: undefined
                            },
                            a11yLabel: "Start shopping"
                          },
                          priority: "high",
                          spacingGroupElement: theme.sectionSpacing._1
                        }), React.createElement(Ui__NewPromoCarouselCard.make, {
                          backgroundColor: theme.colors.primary,
                          textLines: [
                            "We check and clean",
                            "all items you buy."
                          ],
                          foregroundColor: theme.colors.secondaryVariant2,
                          images: {
                            smallScreen: {
                              src: "https://cdn.filestackcontent.com/GZ7qdbnlTa6nR3xsCCrv",
                              width: 363,
                              height: 567
                            },
                            largeScreen: {
                              src: "https://cdn.filestackcontent.com/IISLgmtjS7SJLtswYPOj",
                              width: 1974,
                              height: 978
                            }
                          },
                          alt: "A picture of a woman with a stroller",
                          buttonKind: "secondaryOutline",
                          buttonTitle: "BUY REFURBISHED",
                          routeConfiguration: {
                            route: {
                              TAG: /* Search */18,
                              searchTerm: "Refurbished",
                              loadDataInitially: true,
                              sellingNewItems: undefined,
                              brand: undefined,
                              category: undefined,
                              productType: "Refurbished"
                            },
                            a11yLabel: "Browse refurbished products"
                          },
                          priority: "high",
                          spacingGroupElement: theme.sectionSpacing._1
                        }))), React.createElement(React.Suspense, {
                  children: React.createElement(App__ProductCollection__100.make, {
                        productCollectionQueryRef: result.fragmentRefs
                      }),
                  fallback: null
                }), React.createElement(App__Category__ShopByList.make, {
                  categories: result.fragmentRefs
                }), React.createElement(React.Suspense, {
                  children: React.createElement(App__DiscoverPage$MostLikedCollection, {
                        productCollectionQueryRef: result.fragmentRefs
                      }),
                  fallback: null
                }), React.createElement(Ui__Group.make, {
                  spacing: theme.spacing._12,
                  children: null,
                  containerStyle: [
                    Curry._1(styled, styles.postcardsContainer),
                    widthLimiterStyle
                  ],
                  orientation: postcardsLayout,
                  accessibilityRole: "region"
                }, React.createElement(Ui__Postcard.make, tmp), React.createElement(Ui__Postcard.make, tmp$2)), React.createElement(React.Suspense, {
                  children: React.createElement(App__DiscoverPage$MostViewedCollection, {
                        productCollectionQueryRef: result.fragmentRefs
                      }),
                  fallback: null
                }), React.createElement(Ui__Group.make, tmp$5), React.createElement(Ui__MarkotFeatures.make, {}));
}

var make = App__DiscoverPage;

export {
  make ,
}
/* match Not a pure module */
