// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Card from "../../card/Ui__Card.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__AppLink from "../../routing/Ui__AppLink.bs.js";
import * as Ui__Styling from "../../styling/Ui__Styling.bs.js";
import * as Ui__Responsive from "../../responsive/Ui__Responsive.bs.js";
import * as Ui__ExternalLink from "../../routing/Ui__ExternalLink.bs.js";
import * as Ui__ImageFallback from "../../image/components/imageFallback/Ui__ImageFallback.bs.js";
import * as SimpleReact$DecidrIoUtils from "@decidr-io/utils/src/react/SimpleReact.bs.js";

function Ui__BlogCard(Props) {
  var image = Props.image;
  var title = Props.title;
  var behavior = Props.behavior;
  var description = Props.description;
  var buttonText = Props.buttonText;
  var alt = Props.alt;
  var style = Props.style;
  var testIDOpt = Props.testID;
  var imageSizesOpt = Props.imageSizes;
  var testID = testIDOpt !== undefined ? testIDOpt : "blog-card";
  var imageSizes = imageSizesOpt !== undefined ? imageSizesOpt : "100vw";
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var media = Ui__Responsive.useMedia(undefined)(undefined);
  var match$1 = Curry.app(media.mediaSelect, [
        undefined,
        {
          cardImageHeight: 227,
          contentPaddingHorizontal: theme.spacing._6
        },
        {
          cardImageHeight: 413,
          contentPaddingHorizontal: theme.spacing._6
        },
        {
          cardImageHeight: 281,
          contentPaddingHorizontal: theme.spacing._12
        },
        {
          cardImageHeight: 426,
          contentPaddingHorizontal: theme.spacing._12
        },
        {
          cardImageHeight: 211,
          contentPaddingHorizontal: theme.spacing._6
        },
        {
          cardImageHeight: 272,
          contentPaddingHorizontal: theme.spacing._6
        },
        {
          cardImageHeight: 227,
          contentPaddingHorizontal: theme.spacing._6
        },
        undefined
      ]);
  var contentPaddingHorizontal = match$1.contentPaddingHorizontal;
  var cardImageHeight = match$1.cardImageHeight;
  var match$2 = Curry._1(Ui__AppLink.useRouting, undefined);
  var nativeNavigationHandler = match$2.nativeNavigationHandler;
  var variant = behavior.NAME;
  var internalVariant;
  if (variant === "Button") {
    internalVariant = {
      NAME: "RegularButton",
      VAL: behavior.VAL
    };
  } else if (variant === "Link") {
    var match$3 = behavior.VAL;
    var route = match$3.route;
    internalVariant = nativeNavigationHandler !== undefined ? ({
          NAME: "NativeLink",
          VAL: (function (param) {
              Curry._1(nativeNavigationHandler, Curry._3(Ui__AppLink.makeConfiguration, route, undefined, undefined));
            })
        }) : ({
          NAME: "WebLink",
          VAL: [
            Curry._3(Ui__AppLink.makeConfiguration, route, undefined, undefined),
            match$3.a11yLabel
          ]
        });
  } else {
    var match$4 = behavior.VAL;
    var match$5 = match$4.link;
    var match$6 = Ui__ExternalLink.handle(match$5.route, match$4.baseUrl);
    internalVariant = match$6.NAME === "Web" ? ({
          NAME: "WebLink",
          VAL: [
            Curry._3(Ui__AppLink.makeConfiguration, match$6.VAL, undefined, undefined),
            match$5.a11yLabel
          ]
        }) : ({
          NAME: "RegularButton",
          VAL: match$6.VAL
        });
  }
  if (internalVariant.NAME === "WebLink") {
    var match$7 = internalVariant.VAL;
    var tmp = {
      children: null,
      kind: "sharp",
      spacing: theme.spacing._6,
      backgroundColor: "transparent",
      accessibilityRole: "article",
      includeEndSpacing: true,
      testID: testID
    };
    if (style !== undefined) {
      tmp.style = Caml_option.valFromOption(style);
    }
    return React.createElement(Ui__AppLink.make, {
                configuration: match$7[0],
                children: React.createElement(Ui__Card.make, tmp, image !== undefined ? React.createElement(Ui__Card.$$Image.make, {
                            image: image,
                            size: {
                              NAME: "fixedHeightAutoWidth",
                              VAL: {
                                height: cardImageHeight,
                                sizes: imageSizes
                              }
                            },
                            alt: alt,
                            testID: "blog-card-image"
                          }) : React.createElement(Ui__ImageFallback.make, {
                            height: cardImageHeight
                          }), React.createElement(Ui__Card.Section.make, {
                          children: null,
                          spacing: theme.spacing._4,
                          paddingHorizontal: contentPaddingHorizontal
                        }, React.createElement(Ui__Card.Heading.make, {
                              text: title,
                              level: 2,
                              size: "title3"
                            }), SimpleReact$DecidrIoUtils.renderIfSome(description, (function (text) {
                                return React.createElement(Ui__Card.Description.make, {
                                            text: text,
                                            size: "b2",
                                            testID: "blog-post-description"
                                          });
                              })), React.createElement(Ui__Card.Description.make, {
                              text: buttonText,
                              size: "b2",
                              fontWeight: "_500"
                            }))),
                a11yLabel: match$7[1]
              });
  }
  var tmp$1 = {
    children: null,
    onPress: internalVariant.VAL,
    kind: "sharp",
    spacing: theme.spacing._6,
    backgroundColor: "transparent",
    accessibilityRole: "article",
    includeEndSpacing: true,
    testID: testID
  };
  if (style !== undefined) {
    tmp$1.style = Caml_option.valFromOption(style);
  }
  return React.createElement(Ui__Card.make, tmp$1, image !== undefined ? React.createElement(Ui__Card.$$Image.make, {
                    image: image,
                    size: {
                      NAME: "fixedHeightAutoWidth",
                      VAL: {
                        height: cardImageHeight,
                        sizes: imageSizes
                      }
                    },
                    alt: alt,
                    testID: "blog-card-image"
                  }) : React.createElement(Ui__ImageFallback.make, {
                    height: cardImageHeight
                  }), React.createElement(Ui__Card.Section.make, {
                  children: null,
                  spacing: theme.spacing._4,
                  paddingHorizontal: contentPaddingHorizontal
                }, React.createElement(Ui__Card.Heading.make, {
                      text: title,
                      level: 1,
                      size: "title3"
                    }), SimpleReact$DecidrIoUtils.renderIfSome(description, (function (text) {
                        return React.createElement(Ui__Card.Description.make, {
                                    text: text,
                                    size: "b2"
                                  });
                      })), React.createElement(Ui__Card.Description.make, {
                      text: buttonText,
                      size: "b2",
                      fontWeight: "_500"
                    })));
}

var make = Ui__BlogCard;

export {
  make ,
}
/* react Not a pure module */
